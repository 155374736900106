import type { HydraContext } from '../../../../common'

const [useProvideOriginalValueCache, useOriginalValueCacheRaw] = createInjectionState(
  () => {
    const cache = ref<Record<string, HydraContext>>({})
    const arrayCache = ref<Record<string, HydraContext[]>>({})
    const upsert = (key: string, value: HydraContext) => {
      cache.value[key] = value
    }

    const setArrayCache = (key: string, value: HydraContext[]) => {
      arrayCache.value[key] = value
    }

    const remove = (key: string) => {
      delete cache.value[key]
    }

    const removeArrayCache = (key: string) => {
      delete arrayCache.value[key]
    }

    const reset = () => {
      cache.value = {}
      arrayCache.value = {}
    }

    return {
      cache,
      arrayCache,
      setArrayCache,
      removeArrayCache,
      upsert,
      remove,
      reset,
    }
  },
)

export { useProvideOriginalValueCache }

export function useOriginalValueCache() {
  const store = useOriginalValueCacheRaw()
  if (store == null) {
    throw new Error(
      'Please call `useProvideOriginalValueCache` on the appropriate parent component',
    )
  }
  return store
}
