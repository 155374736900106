import type { InferType } from 'yup'
import type { CaseFileSortEnum } from '../../../../composables/features/case_files/case_file.interface'
import type { ProfileTypeEnum } from '../../../../composables/features/profile/profile.interface'
import dayjs from 'dayjs'
import { type CountryCode, getCountries, isValidPhoneNumber } from 'libphonenumber-js'
import { array, boolean, date, number, object, string } from 'yup'
import { profileTypeOptions } from '../../../../composables'
import { caseFileSortOptions } from '../../../../composables/features/case_files/case_file.interface'
import { legalFormOptions } from '../../../../composables/features/client/client.interface'
import locales from '../../../../composables/locales.json'
import timezones from '../../../../composables/timezones.json'

const localesCodesUppercased = Object.keys(locales).map(key => key.toUpperCase())

interface Invoice {
  number: string
  dateAt: string
  dueDateAt: string
  amount: number
}

export interface CaseFileImportData {
  type: ProfileTypeEnum
  sort: CaseFileSortEnum
  debtorNumber: string
  name: string
  locale: string
  timezone: string
  companyName?: string | null
  addressLine1: string
  addressLine2?: string | null
  zipCode?: string | null
  locality?: string | null
  country?: string | null
  emailAddress: string
  phoneNumber?: string | null
  mobilePhoneNumber?: string | null
  companyLegalForm?: string | null
  chamberOfCommerceNumber?: string | null
  valueAddedTaxNumber?: string | null
  collectionCostGroup?: number | null
  birthDateAt?: string | null
  invoices: Invoice[]
}

export interface CaseFileImportWrite {
  administration: string
  creditor: string
  data: CaseFileImportData[]
}

export function caseFileBaseImportItemSchema(t: ReturnType<typeof useI18n>['t'], countries: CountryCode[]) {
  return object({
    type: number()
      .test('valid-type', (value, ctx) => {
        const options = profileTypeOptions(t)
        const isValid = options.some(option =>
          option.value.toString() === value?.toString()
          || option.label.toLowerCase() === value?.toString().toLowerCase(),
        )
        if (!isValid) {
          const validValues = options.map(option => `${option.value}, ${option.label}`).join(', ')
          return ctx.createError({ message: t('common.forms.oneOf', { values: validValues }) })
        }
        return true
      })
      .transform((_, value) => {
        if (typeof value === 'number')
          return value
        const options = profileTypeOptions(t)
        const matchedOption = options.find(option =>
          option.value.toString() === value
          || option.label.toLowerCase() === value?.toLowerCase(),
        )
        return matchedOption ? matchedOption.value : value
      })
      .required(t('common.forms.required')),
    soort: number()
      .test('valid-sort', (value, context) => {
        const options = caseFileSortOptions(t)
        const isValid = options.some(option =>
          option.value.toString() === value?.toString()
          || option.label.toLowerCase() === value?.toString().toLowerCase(),
        )
        if (!isValid) {
          const validValues = options.map(option => `${option.value}, ${option.label}`).join(', ')
          return context.createError({ message: t('common.forms.oneOf', { values: validValues }) })
        }
        return true
      })
      .transform((_, value) => {
        if (typeof value === 'number')
          return value
        const options = caseFileSortOptions(t)
        const matchedOption = options.find(option =>
          option.value.toString() === value
          || option.label.toLowerCase() === value?.toLowerCase(),
        )
        return matchedOption ? matchedOption.value : value
      })
      .required(t('common.forms.required')),
    debiteurnr: string().required(t('common.forms.required')),
    naam: string().required(t('common.forms.required')),
    taal: string().transform((value) => {
      return value?.toUpperCase()
    }).test('valid-locale', (value, context) => {
      if (!value) {
        return context.createError({ message: t('common.forms.required') })
      }
      if (!localesCodesUppercased.includes(value)) {
        return context.createError({ message: t('common.forms.country') })
      }
      return true
    }).required(t('common.forms.required')),
    tijdzone: string().test('valid-timezone', (value, context) => {
      if (!value) {
        return context.createError({ message: t('common.forms.required') })
      }
      if (!timezones.includes(value)) {
        return context.createError({ message: t('common.forms.required') })
      }
      return true
    }).required(t('common.forms.required')),
    bedrijfsnaam: string().when('type', {
      is: (value: number) => value === 1,
      then: schema => schema.required(t('common.forms.requiredIfBusiness')),
      otherwise: schema => schema.notRequired(),
    }),
    adresregel: string().required(t('common.forms.required')),
    postcode: string().matches(/^\d{4} [A-Z]{2}$/, t('common.forms.postcode')),
    plaats: string().required(t('common.forms.required')),
    land: string().transform((value) => {
      return value?.toUpperCase()
    }).test('valid-country', (value, context) => {
      if (!value) {
        return context.createError({ message: t('common.forms.required') })
      }
      if (!countries.includes(value as CountryCode)) {
        return context.createError({ message: t('common.forms.country') })
      }
      return true
    }).required(t('common.forms.required')),
    email: string().email(t('common.forms.email')).required(t('common.forms.required')),
    telefoonCountry: string().default('NL'),
    telefoon: string()
      .test('validate-phone', t('common.forms.phone'), (value, context) => {
        if (value === undefined || value === '')
          return true
        return isValidPhoneNumber(value, context.parent.telefoonCountry)
      })
      .optional(),
    mobielCountry: string().default('NL'),
    mobiel: string()
      .test('validate-phone', t('common.forms.phone'), (value, context) => {
        if (value === undefined || value === '')
          return true
        return isValidPhoneNumber(value, context.parent.mobielCountry)
      })
      .optional(),
    bedrijfsvorm: string().test('valid-type', (value, ctx) => {
      if (!value) {
        return ctx.parent.type === 1 ? ctx.createError({ message: t('common.forms.requiredIfBusiness') }) : true
      }
      const options = legalFormOptions(t)
      const isValid = options.some(option =>
        option.value.toString() === value?.toString()
        || option.label.toLowerCase() === value?.toString().toLowerCase(),
      )
      if (!isValid) {
        const validValues = options.map(option => `${option.value}, ${option.label}`).join(', ')
        return ctx.createError({ message: t('common.forms.oneOf', { values: validValues }) })
      }
      return true
    }).transform((_, value) => {
      const options = legalFormOptions(t)
      const matchedOption = options.find(option =>
        option.value.toString() === value
        || option.label.toLowerCase() === value?.toLowerCase(),
      )
      return matchedOption ? matchedOption.value : value
    }),
    kvk: string(),
    btw: string(),
    incassokosten: number().transform((_, value) => {
      if (!value)
        return null
      return Number.parseFloat(value)
    }).nullable(),
    geboortedatum: date()
      .transform((_, value) => {
        if (!value)
          return null
        try {
          if (typeof value === 'string') {
            return dayjs(value, 'DD/MM/YYYY').toDate()
          }
          return value
        }
        catch (e) {
          console.log('error', e)
          return null
        }
      })
      .nullable(),
    rekening: string(),
  })
}

export function caseFileImportItemSchema(t: (key: string) => string, countries: CountryCode[]) {
  return object({
    factuurnr: string().required(t('common.forms.required')),
    factuurdatum: date()
      .transform((_, value) => {
        if (!value)
          return null
        try {
          return dayjs(value, 'DD/MM/YYYY').toDate()
        }
        catch (e) {
          console.log('error', e)
          return null
        }
      }),
    vervaldatum: date()
      .transform((_, value) => {
        if (!value)
          return null
        try {
          return dayjs(value, 'DD/MM/YYYY').toDate()
        }
        catch (e) {
          console.log('error', e)
          return null
        }
      })
      .test('valid-due-date', (value, context) => {
        if (context.parent.factuurdatum && value) {
          const factuurDatum = typeof context.parent.factuurdatum === 'string' ? dayjs(context.parent.factuurdatum, 'DD/MM/YYYY').startOf('day') : dayjs(context.parent.factuurdatum).startOf('day')
          const vervalDatum = typeof value === 'string' ? dayjs(value, 'DD/MM/YYYY').startOf('day') : dayjs(value).startOf('day')
          console.log('[useCaseFileImport] - factuurDatum', factuurDatum.format('DD/MM/YYYY'))
          console.log('[useCaseFileImport] - vervalDatum', vervalDatum.format('DD/MM/YYYY'))
          if (vervalDatum.isSame(factuurDatum, 'day') || vervalDatum.isBefore(factuurDatum)) {
            console.log('[useCaseFileImport] - Invalid due date', vervalDatum.format('DD/MM/YYYY'))
            return context.createError({ message: t('common.forms.dueDateAfterInvoiceDate') })
          }
          console.log('[useCaseFileImport] - Valid due date', vervalDatum.format('DD/MM/YYYY'))
        }
        return true
      }),
    bedrag: number().transform((_, value) => {
      if (!value)
        return 0
      return Number.parseFloat(value)
    }).positive(),
  }).concat(caseFileBaseImportItemSchema(t, countries))
}

export type CaseFileImportItemSchema = InferType<ReturnType<typeof caseFileImportItemSchema>>

export function caseFileImportSchema(t: (key: string) => string) {
  return object({
    data: array().of(caseFileImportItemSchema(t, getCountries())).required(t('common.forms.required')).default([]),
  })
}

export type CaseFileImportSchema = InferType<ReturnType<typeof caseFileImportSchema>>

export function csvRowItemSchema(t: (key: string) => string, countries: CountryCode[]) {
  return object({
    id: number().required(t('common.forms.required')),
    showDialog: boolean().optional().default(false),
    invoices: array().of(
      object({
        factuurnr: string().required(t('common.forms.required')),
        factuurdatum: date().required(t('common.forms.required')),
        vervaldatum: date().required(t('common.forms.required')),
        bedrag: number().required(t('common.forms.required')).positive(),
      }),
    ).required(t('common.forms.required')).default([]),
  }).concat(caseFileBaseImportItemSchema(t, countries))
}

export type CsvRowItemSchema = InferType<ReturnType<typeof csvRowItemSchema>>

export function csvRowSchema(t: (key: string) => string) {
  return object({
    data: array().of(csvRowItemSchema(t, getCountries())).required(t('common.forms.required')).default([]),
  })
}

export type CsvRowSchema = InferType<ReturnType<typeof csvRowSchema>>['data']

export interface CsvRowSchemaStringValue {
  type: string
  soort: string
  debiteurnr: string
  naam: string
  taal: string
  tijdzone: string
  bedrijfsnaam: string
  adresregel: string
  postcode: string
  plaats: string
  land: string
  email: string
  telefoonCountry: string
  telefoon: string
  mobielCountry: string
  mobiel: string
  bedrijfsvorm: string
  kvk: string
  btw: string
  incassokosten: string
  geboortedatum: string
  rekening: string
  factuurnr: string
  factuurdatum: string
  vervaldatum: string
  bedrag: string
}
export type CsvRowSchemaStringValues = CsvRowSchemaStringValue[]
