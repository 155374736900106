import type { InferType } from 'yup'
import { object, string } from 'yup'

export function addressRawSchema(_: (key: string) => string) {
  return {
    addressLine1: string().required(),
    addressLine2: string().optional(),
    locality: string().required(),
    region: string().optional(),
    country: string().required(),
    zipCode: string().required(),
  }
}

export function addressObjectSchema(t: (key: string) => string) {
  return object(addressRawSchema(t))
}

export type AddressRawSchema = InferType<ReturnType<typeof addressObjectSchema>>
