import type { DataTableSortEvent } from 'primevue/datatable'
import type { ComputedRef, Ref } from 'vue'
import type { FilterValue } from './table.helpers.ts'
import type { ColumnsType, FiltersType, ServerTableFilterAndSortInterface } from './table.interface.ts'
import { createInjectionState } from '@vueuse/shared'
import { useTableHelpers } from './table.helpers.ts'

interface UseTableInterface<Entity> {
  hasMulti?: boolean | Ref<boolean>
  hasExpand?: boolean
  columnsVersion?: number
  filters?: FiltersType<Entity>
  runOnStart?: boolean
  runOnChange?: boolean
  preventExecute?: boolean
  defaultSort?: Pick<DataTableSortEvent, 'sortOrder' | 'sortField'>
  endpoint: ComputedRef<string> | string
  preventSelect?: (item: Entity) => boolean
  defaultParams?: Ref<URLSearchParams>
  method?: 'get' | 'post'
  body?: Ref<Record<string, any>>
  watch?: any
  disable?: ComputedRef<boolean> | boolean
  hasPerms?: ComputedRef<boolean> | boolean | (() => boolean)
  globalKeys?: (keyof Entity)[]
  columns: ColumnsType<Entity>
  onError: (
    error: any,
    lazyEvent: Ref<DataTableSortEvent | null>,
    filters?: Ref<
      Partial<Record<keyof Entity, FilterValue<Entity>>> &
      Record<'global', FilterValue<Entity>>
    >,
  ) => void
  items?: Ref<Entity[]>
  loading?: Ref<boolean>
  initialLoading?: Ref<boolean>
}

const [useProvideTableRaw, useTableRaw] = createInjectionState(
  <Entity>({
    filters,
    runOnStart = true,
    columnsVersion = 1,
    onError,
    runOnChange = true,
    globalKeys = undefined,
    disable = false,
    hasPerms: hasPermissions,
    preventExecute = false,
    hasMulti = false,
    preventSelect,
    defaultSort = {
      sortField: 'createdAt',
      sortOrder: -1,
    },
    columns: initialColumns,
    defaultParams,
    body,
    method = 'get',
    hasExpand,
    endpoint,
    watch: watcher,
    items: passedItems,
    loading: passedLoading,
    initialLoading: passedInitialLoading,
  }: UseTableInterface<Entity>): ServerTableFilterAndSortInterface<Entity> => {
    const tableCallError = ref<'access_denied' | 'not_found' | null>(null)
    const selectedItems = ref<Entity[]>([]) as Ref<Entity[]>
    const expandedRows = ref<Entity[]>([]) as Ref<Entity[]>
    const items = passedItems || ref([]) // Initialize with the passed items or create a new ref
    const loading = passedLoading || ref(false) // Initialize with the passed loading or create a new ref
    const initialLoading = passedInitialLoading || ref(false) // Initialize with the passed initialLoading or create a new ref
    const { columns } = useProvideColumns(
      initialColumns,
      `${toValue(endpoint)}-${initialColumns.length}-${columnsVersion}`,
      hasMulti,
      hasExpand,
    )
    const hasNext = ref(0)
    const page = ref(1)
    const hasPerms = computed(() =>
      hasPermissions !== undefined ? !!toValue(hasPermissions) : true,
    )

    const isDisabled = computed(() => toValue(disable))

    const { showSorting } = useSorting()
    const { query } = useRoute()
    const {
      filters: filtersRef,
      clearFilter,
      showFilters,
      isFiltering,
    } = useFilters<Entity>({ filters, query })

    const { loadData, lazyEvent, onSort } = useTableHelpers<Entity>({
      runOnStart,
      runOnChange,
      loading,
      disable: isDisabled,
      hasPerms,
      initialLoading,
      defaultSort,
      tableCallError,
      page,
      preventExecute,
      onError,
      endpoint,
      columns: columns as Ref<ColumnsType<Entity>>,
      method,
      defaultParams,
      body,
      hasNext,
      globalKeys,
      showFilters: showFilters || undefined,
      showSorting,
      filters: filtersRef,
      items,
      onFullReset: () => {
        selectedItems.value = []
        expandedRows.value = []
        items.value = []
      },
      onFetch: async (data, { reset, refreshLastPage }) => {
        if (reset) {
          selectedItems.value = []
          items.value = data['hydra:member']
        }
        else {
          if (refreshLastPage) {
            items.value = [
              ...items.value.slice(0, items.value.length - 25),
              ...data['hydra:member'],
            ].filter((v: any, i, a) => a.findIndex((t: any) => t.id === v.id) === i)
          }
          else {
            items.value = [...items.value, ...data['hydra:member']].filter((v: any, i, a) => a.findIndex((t: any) => t.id === v.id) === i)
          }
        }
      },
    })

    if (watcher) {
      watch(watcher, () => {
        loadData({
          reset: true,
        }).then()
      })
    }

    const { nextPage, prevPage } = usePaginate({
      hasNext,
      page,
      loadData,
    })

    const onRowSelectionUpdate = (selection: Entity[]) => {
      console.log('onRowSelectionUpdate', selection[0])
      if (preventSelect) {
        selectedItems.value = selection.filter(s => !preventSelect(s))
      }
    }

    watch(
      () => isDisabled.value,
      (disabled) => {
        if (disabled !== undefined) {
          if (disabled === false) {
            loadData({
              reset: true,
            }).then()
          }
          else {
            items.value = []
            clearFilter()
            page.value = 1
            hasNext.value = 0
            selectedItems.value = []
            expandedRows.value = []
          }
        }
      },
    )

    return {
      initialLoading,

      nextPage,

      columns: columns as Ref<ColumnsType<Entity>>,
      prevPage,
      page,
      hasNext,
      hasPerms,
      loadData,
      lazyEvent,
      tableCallError,
      onRowSelectionUpdate,
      loading,
      showFilters,
      hasMulti,
      showSorting,
      hasExpand,
      expandedRows,
      items,
      onSort,
      isFiltering,
      selectedItems,
      defaultSort,
      clearFilter,
      disable: isDisabled,
      filters: filtersRef,
    }
  },
)

function useTableInjected() {
  const tableStore = useTableRaw()
  if (tableStore == null) {
    throw new Error(
      'Please call `useProvideTable` on the appropriate parent component',
    )
  }
  return tableStore
}

const useProvideTable = useProvideTableRaw as <T>(
  data: UseTableInterface<T>,
) => ServerTableFilterAndSortInterface<T>

export { useProvideTable, useTableInjected }
