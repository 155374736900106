import type { InferType } from 'yup'
import { array, mixed, object } from 'yup'
import { AbstractValueTypeEnum, stringTemplateObjectSchema } from '../../../../../../../composables'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreateSmsQueueJobTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  recipients: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  locale: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  automatic: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batch: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batchIdentifier: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
}

export function automationCreateSmsQueueJobActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.CreateSmsQueueJobAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.CreateSmsQueueJobAction),
    'recipients': array()
      .of(
        automationValueObjectSchema(t, {
          allowedTypes: allowedCreateSmsQueueJobTypesMap.recipients,
        }),
      )
      .min(1)
      .default([]),
    'baseContext': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateSmsQueueJobTypesMap.baseContext,
    }),
    'body': stringTemplateObjectSchema(t),
    'automatic': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateSmsQueueJobTypesMap.automatic,
    }),
    'batch': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateSmsQueueJobTypesMap.batch,
    }),
    'batchIdentifier': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateSmsQueueJobTypesMap.batchIdentifier,
      required: false,
    }),
  })
}

export type AutomationCreateSmsQueueJobActionObjectSchema = InferType<
  ReturnType<typeof automationCreateSmsQueueJobActionObjectSchema>
>
