import type { ActivityGenericTypeHydraCollectionItem } from './activity_generic_types.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useActivityGenericTypesStore = createGlobalState(() => {
  const activityGenericTypes = useStorage<Record<string, ActivityGenericTypeHydraCollectionItem> | null>('activity-generic-types', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<ActivityGenericTypeHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    activityGenericTypes.value = null
  }

  const { hasPermission } = usePermissions()

  const allowReadActivityGenericTypes = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.ACTIVITY_GENERIC_TYPE
        .READ_COLLECTION,
    ),
  )
  async function setActivityGenericTypes(): Promise<void> {
    if (!allowReadActivityGenericTypes.value) {
      activityGenericTypes.value = null
      return
    }
    await getAll<ActivityGenericTypeHydraCollectionItem>({ url: '/api/activity_generic_types' }).then((data: ActivityGenericTypeHydraCollectionItem[]) => {
      activityGenericTypes.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, ActivityGenericTypeHydraCollectionItem>)
      return data
    })
  }

  const activityGenericTypesOptions = computed(() => {
    return activityGenericTypes.value
      ? Object.values(activityGenericTypes.value).map(status => ({
        label: status.name,
        value: status['@id'],
      })).sort((a, b) => a.label.localeCompare(b.label))
      : []
  })

  return {
    allowReadActivityGenericTypes,
    activityGenericTypesOptions,
    setActivityGenericTypes,
    reset,
    items,
    activityGenericTypes,
    loading,
    initialLoading,
  }
})
