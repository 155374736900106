import type { InferType } from 'yup'
import type { StringTemplateObjectSchema } from '../../../../../../../composables'
import { mixed, object, string } from 'yup'
import { AbstractValueTypeEnum, stringTemplateObjectSchema } from '../../../../../../../composables'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreatePrintQueueJobTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  locale: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  automatic: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batch: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  batchIdentifier: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
}

export function automationCreatePrintQueueJobActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.CreatePrintQueueJobAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.CreatePrintQueueJobAction),
    'baseContext': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.baseContext,
    }),
    'body': stringTemplateObjectSchema(t).default({
      '#type': 'Value.StringTemplate',
      'renderer': 2,
      'template': {
        '#type': 'Value.EntityReference',
      },
      'locale': {
        '#type': 'Value.Reference',
        'reference': '',
      },
    }),
    'paperSizeOverride': string().when('body', ([body], schema) => {
      const bodySchema = body as StringTemplateObjectSchema
      if (bodySchema && (bodySchema.template as StringTemplateObjectSchema)['#type'] === AbstractValueTypeEnum.FixedValue) {
        return schema.required()
      }
      return schema.optional().nullable().default(null)
    }),
    'automatic': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.automatic,
    }),
    'fileNameTemplateOverride': mixed().when('body', ([body], schema) => {
      const bodySchema = body as StringTemplateObjectSchema
      if (bodySchema && (bodySchema.template as StringTemplateObjectSchema)['#type'] === AbstractValueTypeEnum.FixedValue) {
        return schema.required()
      }
      return schema.optional().nullable().default(null)
    }),
    'batch': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.batch,
    }),
    'batchIdentifier': automationValueObjectSchema(t, {
      allowedTypes: allowedCreatePrintQueueJobTypesMap.batchIdentifier,
      required: false,
    }),
  })
}

export type AutomationCreatePrintQueueJobActionObjectSchema = InferType<
  ReturnType<typeof automationCreatePrintQueueJobActionObjectSchema>
>
