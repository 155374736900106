import type { HydraContext, HydraTimeBased } from '../../../common'
import { type InferType, object, string } from 'yup'

export function administrationSettingsObjectSchema() {
  return object({
    invoiceNumberSequence: string().required(),
    subscriptionNumberSequence: string().required(),
    commissionDebitFinancialAccount: string().required(),
    invoiceCreditFinancialAccount: string().required(),
    caseFileCreditFinancialAccount: string().required(),
  })
}

export type AdministrationSettingsObjectSchema = InferType<ReturnType<typeof administrationSettingsObjectSchema>>

export interface AdministrationSettingsHydraItem extends HydraContext, HydraTimeBased {
  '@type': 'AdministrationSettings'
  'invoiceNumberSequence': string
  'subscriptionNumberSequence': string
  'invoiceCreditFinancialAccount': string
  'caseFileCreditFinancialAccount': string
  'commissionDebitFinancialAccount': string
  'administration': string // iri reference to Client
  'global'?: boolean
}

export type AdministrationSettingsHydraCollectionItem = AdministrationSettingsHydraItem
export interface AdministrationSettingWrite {
  invoiceNumberSequence: string
  subscriptionNumberSequence: string
  commissionDebitFinancialAccount: string
  invoiceCreditFinancialAccount: string
  caseFileCreditFinancialAccount: string
  // Added inside transform logic.
  administration?: string
}
