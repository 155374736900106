import type { HydraCollection } from '../../../../common'
import type { ExactDivisionHydraItem } from '../../../../composables'
import { PermissionAttributes } from '../../../../composables'
import { useGlobalClientSettings } from '../../../../composables/features/settings/useGlobalClientSettings.ts'

export const useExactDivisions = createGlobalState(() => {
  const { hasAppSubscription } = useGlobalClientSettings()
  const divisions = ref<ExactDivisionHydraItem[]>([])
  const { hasPermission } = usePermissions()

  const allowDivisionsRead = computed(() => hasPermission(
    PermissionAttributes.GLOBAL.OPERATION.INTERNAL_APP_EXACT.READ_DIVISIONS,
  ))
  const loadingDivisions = ref(false)
  const { onError } = useServerErrorHandler()
  const { firstTenant } = useTenantsStore()

  const loadDivisions = async (load = false): Promise<void> => {
    if (loadingDivisions.value)
      return
    if (load) {
      loadingDivisions.value = true
    }
    console.log('loadDivisions', hasAppSubscription.value, allowDivisionsRead.value, firstTenant.value)
    if (hasAppSubscription.value && allowDivisionsRead.value && firstTenant.value) {
      api.get<HydraCollection<ExactDivisionHydraItem>>(`/api/internal_apps/${firstTenant.value.id}/exact/divisions`)
        .then(({ data }) => {
          divisions.value = data['hydra:member']
        })
        .catch((e) => {
          onError(e)
        })
        .finally(() => {
          loadingDivisions.value = false
        })
    }
    else {
      loadingDivisions.value = false
    }
  }
  const divisionOptions = computed(() => divisions.value.map(division => ({
    label: division.name,
    value: division.id,
  })))
  return {
    hasAppSubscription,
    divisionOptions,
    loadDivisions,
    loadingDivisions,
    allowDivisionsRead,
  }
})
