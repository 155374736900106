import type { AxiosInstance, AxiosResponse } from 'axios'
import type { InterceptorConfig } from '../../createApi.ts'
import type { NetworkError, ServerError } from '../../Error'
import { InterceptorType } from '../../createApi.ts'
import { ClientError } from '../../Error'
import { HttpStatusCode } from '../../HttpStatusCode'

export function RefreshTokenResponseInterceptor(
  axiosInstance: AxiosInstance,
  refreshToken: () => Promise<void>,
  excludedRoutes: string[] = [],
): InterceptorConfig<InterceptorType.RESPONSE> {
  async function rejected(failedRequest: ClientError | ServerError | NetworkError): Promise<AxiosResponse<any, any>> {
    // We can't refresh the token if it's not a client error.
    if (!(failedRequest instanceof ClientError))
      throw failedRequest

    if (
      !failedRequest.config.url
      || failedRequest.response.status !== HttpStatusCode.HTTP_UNAUTHORIZED
      || excludedRoutes.includes(failedRequest.config.url)
      || (failedRequest.config as any).retry === true
    ) {
      throw failedRequest
    }

    await refreshToken()
    const config = { ...failedRequest.config, retry: true }

    return axiosInstance.request(config as any)
  }

  return {
    name: 'RefreshTokenResponseInterceptor',
    type: InterceptorType.RESPONSE,
    priority: 100,
    rejected,
  }
}
