import type { RouteRecordRaw } from 'vue-router'
import { PermissionResources } from 'lib'

export const FinancialRoutes: RouteRecordRaw[] = [
  {
    path: 'invoices',
    name: 'invoices',
    meta: {
      permissionResources: [PermissionResources.INVOICE, PermissionResources.INVOICE_LINE],
      navbar: {
        name: 'invoices',
        icon: ['far', 'file-invoice'],
      },
    },
    component: () => import('lib/src/components/Features/invoices/Invoices.vue'),
  },
  {
    path: 'invoices/new',
    name: 'invoices_new',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.INVOICE.CREATE],
      parent: 'invoices',
    },
    component: () => import('lib/src/components/Features/invoices/create/InvoiceCreate.vue'),
  },
  {
    path: 'payment_bulk',
    name: 'payment_bulk',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.PAYMENT.CREATE_ANY],
      parent: 'payment_bulk',
      navbar: {
        name: 'payment_bulk',
        icon: ['far', 'credit-card'],
      },
    },
    component: () => import('lib/src/components/Features/casefiles/edit/payments/Bulk/EntityPaymentBulk.vue'),
  },
  {
    path: 'invoices/:id',
    name: 'invoice',
    meta: {
      // permissionsFuzzy: [PermissionAttributes.CONTEXT.CLIENT.OPERATION.INVOICE.READ_SINGLE],
      parent: 'invoices',
    },
    component: () => import('lib/src/components/Features/invoices/edit/InvoiceEdit.vue'),
  },
  {
    path: 'subscriptions',
    name: 'subscriptions',
    meta: {
      permissionResources: [PermissionResources.SUBSCRIPTION, PermissionResources.SUBSCRIPTION_LINE],
      navbar: {
        name: 'subscriptions',
        icon: ['far', 'repeat'],
      },
    },
    component: () => import('lib/src/components/Features/subscriptions/Subscriptions.vue'),
  },
  {
    path: 'subscriptions/new',
    name: 'subscriptions_new',
    meta: {
      permissions: [PermissionAttributes.GLOBAL.OPERATION.SUBSCRIPTION.CREATE],
      parent: 'subscriptions',
    },
    component: () => import('lib/src/components/Features/subscriptions/create/SubscriptionCreate.vue'),
  },
  {
    path: 'subscriptions/:id',
    name: 'subscription',
    meta: {
      parent: 'subscriptions',
    },
    component: () => import('lib/src/components/Features/subscriptions/edit/SubscriptionEdit.vue'),
  },
  {
    path: 'payouts',
    name: 'payouts',
    meta: {
      permissionResources: [PermissionResources.PAYOUT, PermissionResources.PAYOUT_BATCH],
      navbar: {
        name: 'payouts',
        icon: ['far', 'money-simple-from-bracket'],
      },
    },
    component: () => import('../../../../../packages/lib/src/components/Features/payouts/Payouts.vue'),
  },
  {
    path: 'payment-plans',
    name: 'payment_plans',
    meta: {
      permissionResources: [PermissionResources.COLLECTION_COST_GROUP],
      navbar: {
        name: 'payment_plans',
        icon: ['far', 'money-bill'],
      },
    },
    component: () =>
      import(
        '../../../../../packages/lib/src/components/Features/payment_plan_groups/PaymentPlanGroups.vue'
      ),
  },
]

export default FinancialRoutes
