import type { SettlementHydraItem } from './SettlementCalculator.interface'
import { isAxiosError } from 'axios'

export function useCaseFileSettlementHelper(caseFileId: string | number | Ref<number> | ComputedRef<string | undefined>) {
  const settlementHydraItem = ref<SettlementHydraItem | null>(null)
  const loading = ref(false)
  const { onError } = useServerErrorHandler()
  const getSettlement = async () => {
    if (loading.value) {
      return
    }
    loading.value = true
    try {
      const { data } = await api.get<null | SettlementHydraItem>(`/api/case_files/${isRef(caseFileId) ? caseFileId.value : caseFileId}/settlement`).catch((error) => {
        console.error('SettlementCalculator:error', error)
        if (isAxiosError(error) && error.response?.status === 404) {
          settlementHydraItem.value = null
          return {
            data: null,
          }
        }
        throw error
      })
      if (data) {
        settlementHydraItem.value = data
      }
    }
    catch (error: any) {
      onError(error)
    }
    finally {
      loading.value = false
    }
  }
  onMounted(async () => {
    getSettlement().then()
  })
  return {
    settlementHydraItem,
    loading,
    getSettlement,
  }
}
