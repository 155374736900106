// noinspection SuspiciousTypeOfGuard

import type { AutomationV1ObjectSchema } from './automationv1.interface.ts'

import type { AutomationActionObjectSchema } from './Editor/Action/automation_action_edit.interface.ts'

import type { AutomationConditionObjectSchema } from './Editor/Condition/automation_condition_edit.interface.ts'

export function useAutomationTransformers() {
  const inputTransformer = (data: {
    actions: (AutomationActionObjectSchema['action'])[]
    conditions: (AutomationConditionObjectSchema['condition'])[]
    name: string
    trigger: {
      'entity': number
      'event': number
      '#type': string
    }
  }) => {
    return data
  }

  const outputTransformer = (data: Partial<AutomationV1ObjectSchema>) => {
    console.log('data before:', data)

    const traverseAndConvert = (obj: any) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          if (item && typeof item === 'object' && !Array.isArray(item)) {
            traverseAndConvert(item)
          }
        })
      }
      else if (obj && typeof obj === 'object') {
        for (const key in obj) {
          if (obj[key] && typeof obj[key] === 'object') {
            if (!Array.isArray(obj[key]) && Object.keys(obj[key]).length === 0) {
              obj[key] = null
            }
            else {
              traverseAndConvert(obj[key])
            }
          }
        }
      }
    }

    traverseAndConvert(data)
    console.log('data after:', data)
    return data
  }
  return {
    inputTransformer,
    outputTransformer,
  }
}
