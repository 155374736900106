import type { TreeSelectionKeys } from 'primevue/tree'
import type { TreeNode } from 'primevue/treenode'
import type { StateHandler } from 'v3-infinite-loading/lib/types'
import type { HydraCollection } from '../../../common'
import type {
  MediaFolderHydraCollectionItem,
  MediaFolderTreeNode,
  MediaObjectHydraItem,
  RouteMenuItem,
} from '../../../composables'
import { createInjectionState } from '@vueuse/shared'
import { MediaObjectScopeEnum, PermissionAttributes } from '../../../composables'

const [useProvideMediaLibraryFolders, useMediaLibraryFoldersRaw]
  = createInjectionState(({ scope, multiSelect = false, initialSelectedMediaObjects, allowedMimeTypes }: {
    scope?: MediaObjectScopeEnum
    multiSelect: boolean
    initialSelectedMediaObjects?: MediaObjectHydraItem[]
    allowedMimeTypes?: string[]

  }) => {
    const selectedMediaObjects = ref<MediaObjectHydraItem[]>(initialSelectedMediaObjects ?? [])

    const loading = ref(true)
    const loadingMediaObjects = ref(false)
    const { onError } = useServerErrorHandler()
    const { hasPermission } = usePermissions()
    const mediaObjects = ref<MediaObjectHydraItem[]>([])
    const mediaObjectsCurrentPage = ref<number>(1)
    const mediaObjectsHasNextPage = ref<boolean>(false)
    const selectedKeys = ref<TreeSelectionKeys>({})
    const expandedKeys = ref({})
    const nodes = ref<MediaFolderTreeNode[]>([])
    const { t } = useI18n()
    const collapseAllChilds = (node: MediaFolderTreeNode) => {
      if (node.children) {
        node.children.forEach((child) => {
          expandedKeys.value = {
            ...expandedKeys.value,
            [child.key as string]: false,
          }
          collapseAllChilds(child)
        })
      }
    }

    async function onNodeSelect(node: MediaFolderTreeNode) {
      expandedKeys.value = {
        ...expandedKeys.value,
        [node.key as string]: true,
      }
      await getFoldersForExpandedNode(node)
      collapseAllChilds(node)
    }

    const icons: Record<MediaObjectScopeEnum, string> = {
      [MediaObjectScopeEnum.AUTOMATION]: 'pi pi-fw pi-replay',
      [MediaObjectScopeEnum.CORRESPONDENCE_TEMPLATE]: 'pi pi-fw pi-file',
    }

    onMounted(() => {
      if (scope) {
        console.log('Scope:', scope)
        nodes.value = [
          {
            key: `scope-${scope}`,
            label: t(`media.library.scopes.${scope}`),
            scope,
            icon: icons[scope] ?? 'pi pi-fw pi-inbox',
            leaf: false,
            selectable: false,
            loading: false,
            children: [],
          },
        ]
      }
      else {
        nodes.value = Object.values(MediaObjectScopeEnum)
          .filter(v => typeof v === 'number')
          .map((scope) => {
            return {
              key: `scope-${scope}`,
              label: t(`media.library.scopes.${scope}`),
              scope: scope as MediaObjectScopeEnum,
              icon: icons[scope as MediaObjectScopeEnum] ?? 'pi pi-fw pi-inbox',
              leaf: false,
              loading: false,
              selectable: false,
              children: [],
            }
          })
      }
    })

    const allowReadMediaFolders = computed(() => {
      return hasPermission(
        PermissionAttributes.GLOBAL.OPERATION.MEDIA_OBJECT
          .READ_COLLECTION_VIA_PAYOUT_BATCH,
      )
    })

    const convertFolderToTreeNode = (
      item: MediaFolderHydraCollectionItem,
      scope: MediaObjectScopeEnum,
      parent?: string,
    ): MediaFolderTreeNode => {
      return {
        key: `${item.id}`,
        label: item.name,
        scope,
        item,
        parent,
        icon: 'pi pi-fw pi-inbox',
        leaf: false,
        loading: false,
        children: [],
      }
    }

    const selectNode = async (node: MediaFolderTreeNode) => {
      selectedKeys.value = {
        [node.key as string]: true,
      }
      await onNodeSelect(node)
    }

    const getFolders = async (
      scope: MediaObjectScopeEnum,
      onlySelectIfNoneSelected: boolean = false,
    ) => {
      try {
        if (!allowReadMediaFolders.value) {
          return
        }
        const params = new URLSearchParams()
        params.append('scope[]', scope.toString() as string)
        const { data } = await api.get<
          HydraCollection<MediaFolderHydraCollectionItem>
        >('/api/media_folders', { params })

        const newNodes = data['hydra:member'].map(item =>
          convertFolderToTreeNode(item, scope),
        )
        const node = nodes.value.find(node => node.scope === scope)
        if (node) {
          node.children = newNodes
        }
        if (newNodes[0]?.key) {
          if (
            onlySelectIfNoneSelected
            && Object.keys(selectedKeys.value).length > 0
          ) {
            return
          }
          await selectNode(newNodes[0])
        }
      }
      catch (error) {
        onError(error)
      }
    }

    const getAllFolders = async () => {
      if (scope) {
        await getFolders(scope)
        expandedKeys.value = {
          [`scope-${scope}`]: true,
        }
      }
      else {
        for (const [index, scope] of Object.values(MediaObjectScopeEnum)
          .filter(v => typeof v === 'number')
          .entries()) {
          await getFolders(scope as MediaObjectScopeEnum, true)

          if (index === 0) {
            expandedKeys.value = {
              [`scope-${scope}`]: true,
            }
          }
        }
      }
    }

    onMounted(async () => {
      try {
        await getAllFolders()
      }
      finally {
        loading.value = false
      }
    })

    function findNodeDeeply(
      nodes: MediaFolderTreeNode[],
      key: string,
    ): MediaFolderTreeNode | null {
      for (const node of nodes) {
        if (node.key === key) {
          return node
        }
        if (node.children) {
          const found = findNodeDeeply(
            node.children as MediaFolderTreeNode[],
            key,
          )
          if (found) {
            return found
          }
        }
      }
      return null
    }

    const selectedKey = computed(() => {
      return selectedKeys.value && Object.keys(selectedKeys.value)?.length > 0
        ? Object.keys(selectedKeys.value)?.[0]
        : null
    })

    const selectedFolder = computed<MediaFolderTreeNode | null>(() => {
      return selectedKey.value
        ? findNodeDeeply(nodes.value, selectedKey.value)
        : null
    })

    const getMediaObjectsForFolder = async ({
      value,
      state,
      reset = false,
    }: {
      value?: MediaFolderTreeNode | null
      state?: StateHandler
      reset?: boolean
    }) => {
      try {
        if (!value?.item?.id) {
          return
        }
        if (reset) {
          mediaObjectsCurrentPage.value = 1
          mediaObjectsHasNextPage.value = true
        }
        if (state) {
          state.loading()
        }
        loadingMediaObjects.value = true

        const params = new URLSearchParams()
        params.append('perPage', '25')
        params.append('page', mediaObjectsCurrentPage.value.toString())

        const url = `/api/media_folders/${value?.item?.id}/media_objects`
        const { data } = await api.get<HydraCollection<MediaObjectHydraItem>>(
          url,
          {
            params,
          },
        )
        if (reset) {
          mediaObjects.value = data['hydra:member']
        }
        else {
          mediaObjects.value = [...mediaObjects.value, ...data['hydra:member']]
        }
        if (state) {
          if (data['hydra:member'].length < 25) {
            state.complete()
            mediaObjectsHasNextPage.value = false
          }
          else {
            state.loaded()
            mediaObjectsHasNextPage.value = true
          }
        }
      }
      catch (error) {
        onError(error)
        if (state) {
          state.error()
        }
      }
      finally {
        loadingMediaObjects.value = false
      }
    }

    watch(
      () => selectedFolder.value,
      (value) => {
        mediaObjects.value = []
        getMediaObjectsForFolder({ value, reset: true }).then()
      },
    )

    const nextPage = async ({ state }: { state: StateHandler }) => {
      if (!selectedFolder.value || loadingMediaObjects.value) {
        return
      }
      if (!mediaObjectsHasNextPage.value) {
        state.complete()
        return
      }
      mediaObjectsCurrentPage.value++
      await getMediaObjectsForFolder({
        value: selectedFolder.value,
        state,
      }).finally(() => {
        loadingMediaObjects.value = false
      })
    }

    function onNodeCollapse(node: TreeNode) {
      if (node.key) {
        const nodeByKey = findNodeDeeply(nodes.value, node.key)
        if (nodeByKey) {
          nodeByKey.children = []
        }
      }
    }

    // find deeply
    const findNodeByParentIri = (iri: string) => {
      const find = (
        nodes: MediaFolderTreeNode[],
      ): MediaFolderTreeNode | null => {
        for (const node of nodes) {
          if (node.item?.['@id'] === iri) {
            return node
          }
          if (node.children) {
            const found = find(node.children as MediaFolderTreeNode[])
            if (found) {
              return found
            }
          }
        }
        return null
      }
      return find(nodes.value)
    }

    async function getFoldersForExpandedNode(node: MediaFolderTreeNode) {
      if (node.key && node.scope) {
        // @ts-ignore
        const isExpanded = expandedKeys.value[node.key]
        if (!isExpanded)
          return

        console.log('Getting children for node', node.key)
        const nodeByKey = findNodeDeeply(nodes.value, node.key)
        if (nodeByKey) {
          nodeByKey.loading = true
          try {
            let url = `/api/media_folders?scope[]=${node.scope}`

            if (node.item?.id) {
              url = `/api/media_folders/${node.item?.id}/media_folders`
            }

            const { data }
              = await api.get<HydraCollection<MediaFolderHydraCollectionItem>>(
                url,
              )
            // if (data['hydra:member']?.length < 1) {
            //   node.leaf = true
            // }
            nodeByKey.children = data['hydra:member'].map(item =>
              convertFolderToTreeNode(
                item,
                node.scope as MediaObjectScopeEnum,
                node.item?.['@id'],
              ),
            )
          }
          catch (error) {
            onError(error)
          }
          finally {
            nodeByKey.loading = false
          }
        }
      }
    }

    const computedObjects = computed(() => {
      if (selectedFolder.value) {
        return mediaObjects.value
      }
      else {
        return []
      }
    })

    const addMediaObject = async (mediaObject: MediaObjectHydraItem) => {
      if (!selectedFolder.value) {
        return
      }
      mediaObjects.value = [mediaObject, ...mediaObjects.value]
    }
    const removeMediaObject = async (mediaObject: MediaObjectHydraItem) => {
      if (!selectedFolder.value) {
        return
      }
      mediaObjects.value = mediaObjects.value.filter(
        mo => mo.id !== mediaObject.id,
      )
    }

    const breadCrumbs = computed<RouteMenuItem[]>(() => {
      if (!selectedFolder.value) {
        return []
      }
      const crumbs: RouteMenuItem[] = []
      let parent: MediaFolderTreeNode | null = selectedFolder.value
      let isRoot = true
      while (parent) {
        // Capture the current state of `parent` for each loop iteration
        (function (currentParent) {
          crumbs.unshift({
            label: currentParent.label,
            route: {} as any,
            isActive: isRoot,
            command: !isRoot
              ? () => {
                  console.log('Selecting parent', currentParent)
                  if (!currentParent)
                    return
                  selectNode(currentParent).then()
                }
              : undefined,
          })
          isRoot = false
        })(parent) // Immediately invoke with the current parent

        if (parent.parent) {
          parent = findNodeByParentIri(parent.parent as string)
        }
        else {
          parent = null
        }
      }
      return crumbs
    })

    const selectMediaObject = (mediaObject: MediaObjectHydraItem) => {
      // select or unselect
      if (selectedMediaObjects.value.find(mo => mo.id === mediaObject.id)) {
        selectedMediaObjects.value = selectedMediaObjects.value.filter(
          mo => mo.id !== mediaObject.id,
        )
      }
      else {
        selectedMediaObjects.value = [...selectedMediaObjects.value, mediaObject]
      }
    }

    return {
      selectMediaObject,
      selectedMediaObjects,
      breadCrumbs,
      findNodeByParentIri,
      selectedFolder,
      selectedKeys,
      addMediaObject,
      onNodeSelect,
      getAllFolders,
      loading,
      multiSelect,
      nextPage,
      allowedMimeTypes,
      mediaObjectsCurrentPage,
      loadingMediaObjects,
      selectNode,
      mediaObjectsHasNextPage,
      nodes,
      mediaObjects: computedObjects,
      expandedKeys,
      onNodeCollapse,
      removeMediaObject,
      getFoldersForExpandedNode,
    }
  })
export { useProvideMediaLibraryFolders }

// If you want to hide `useExport` and wrap it in default value logic or throw error logic, please don't export `useExport`
export function useMediaLibraryFolders() {
  const state = useMediaLibraryFoldersRaw()
  if (state == null) {
    throw new Error(
      'Please call `useProvideMediaLibraryFolders` on the appropriate parent component',
    )
  }
  return state
}
