import type { MappedEntity } from '../mapping.interface.ts'
import { HydraDatesMap, NameMap } from '../DefaultMap.ts'
import { MappingTypesEnum } from '../MappingTypesEnum.ts'

export const OrganizationMapped: MappedEntity = {
  id: {
    type: MappingTypesEnum.NUMBER,
    label: 'common.id.label',
    placeholder: 'common.id.placeholder',
  },
  ...NameMap,
  ...HydraDatesMap,
  emailAddress: {
    type: MappingTypesEnum.STRING,
    label: 'profile.emailAddress.label',
    placeholder: 'profile.emailAddress.placeholder',
  },
  phoneNumber: {
    type: MappingTypesEnum.STRING,
    label: 'profile.phoneNumber.label',
    placeholder: 'profile.phoneNumber.placeholder',
  },
  mobilePhoneNumber: {
    type: MappingTypesEnum.STRING,
    label: 'profile.mobilePhoneNumber.label',
    placeholder: 'profile.mobilePhoneNumber.placeholder',
  },
}
