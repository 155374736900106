import type { HydraCollectionFull } from '../../common'
import type { ClientHydraItem } from '../features'
import { refreshToken } from '../api'
import {
  useActivityGenericTypesStore,
  useAdministrationStore,
  useMediaObjectSortsStore,
  useNumberSequencesStore,
  useTenantsStore,
  useThirdPartySortsStore,
  useUnitsStore,
  useUserStore,
} from '../features'
import { useCaseFileCloseReasonsStore } from '../features/case_file_close_reasons/useCaseFileCloseReasonsStore.ts'
import { useTaskListStore } from '../features/task_lists/useTaskListStore.ts'

export function useAuthListener({
  shouldFetchClients = true,
  shouldFetchStatusses = true,
}: {
  shouldFetchClients?: boolean
  shouldFetchStatusses?: boolean
} = {}) {
  const loading = ref(false)

  const { setPreferredClient, setHasMultipleClients, isRemembered }
    = useAuthStore()
  const { setAdministrations } = useAdministrationStore()
  const { setStatusses } = useCaseFileStore()
  const { setActivityGenericTypes } = useActivityGenericTypesStore()
  const { setCaseFileCloseReasons } = useCaseFileCloseReasonsStore()
  const { setEmployees } = useUserStore()
  const { setMediaObjectSorts } = useMediaObjectSortsStore()
  const { setUnits } = useUnitsStore()
  const { setTaskLists } = useTaskListStore()
  const { setTenants } = useTenantsStore()
  const { setThirdPartySorts } = useThirdPartySortsStore()
  const { hasPermission } = usePermissions()
  const { setNumberSequences } = useNumberSequencesStore()
  const loadData = async (_ = true) => {
    if (loading.value)
      return

    loading.value = true
    try {
      await refreshToken()
      /* This will get the first client that the user has access to and set it has preferred and checks if totalitem is more then 1 */
      if (
        shouldFetchClients
        && hasPermission(
          PermissionAttributes.CONTEXT.CLIENT.OPERATION.CLIENT
            .READ_SINGLE,
          { fuzzy: true },
        )
      ) {
        await api
          .get<
          HydraCollectionFull<ClientHydraItem>
        >('/api/clients?partial=true&perPage=2&page=1')
          .then(({ data }) => {
            if (data['hydra:member'].length > 0) {
              setPreferredClient(data['hydra:member'][0])
              setHasMultipleClients(data['hydra:member'].length > 1)
            }
          })
      }
      // Get less important data
      if (shouldFetchStatusses) {
        setStatusses().then()
      }

      setEmployees().then()
      setAdministrations().then()
      setActivityGenericTypes().then()
      setTaskLists().then()
      setCaseFileCloseReasons().then()
      setMediaObjectSorts().then()
      setUnits().then()
      setNumberSequences().then()
      setThirdPartySorts().then()
      setTenants().then()
    }
    catch (e) {
      console.error(e)
    }
    finally {
      loading.value = false
    }
  }
  watch(
    () => isRemembered.value,
    (_, oldValue) => {
      // If the refresh token is stored in local storage, we can refresh the token but only if it was previously stored in local storage.
      // this is why we check if the old value is false to prevent this from happineing when the user logs in.
      loadData(oldValue === false).then()
    },
    { immediate: true },
  )
}
