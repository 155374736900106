import type { ThirdPartySortHydraCollectionItem } from './third_party_sorts.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useThirdPartySortsStore = createGlobalState(() => {
  const thirdPartySorts = useStorage<Record<string, ThirdPartySortHydraCollectionItem> | null>('third-party-sorts', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<ThirdPartySortHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    thirdPartySorts.value = null
  }

  const { hasPermission } = usePermissions()
  const allowReadThirdPartySorts = computed(() => hasPermission(PermissionAttributes.GLOBAL.OPERATION.THIRD_PARTY_SORT.READ_COLLECTION))

  async function setThirdPartySorts(): Promise<void> {
    if (!allowReadThirdPartySorts.value) {
      thirdPartySorts.value = null
      return
    }
    await getAll<ThirdPartySortHydraCollectionItem>({ url: '/api/third_party_sorts' }).then((data: ThirdPartySortHydraCollectionItem[]) => {
      thirdPartySorts.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, ThirdPartySortHydraCollectionItem>)
      return data
    })
  }

  const thirdPartySortsOptions = computed(() => {
    return thirdPartySorts.value
      ? Object.values(thirdPartySorts.value).map(status => ({
        label: status.name,
        value: status['@id'],
      }))
      : []
  })

  return {
    allowReadThirdPartySorts,
    thirdPartySortsOptions,
    setThirdPartySorts,
    reset,
    items,
    thirdPartySorts,
    loading,
    initialLoading,
  }
})
