import type { HydraCollection } from '../../../../../../common/hydra.interface'
import type { AdministrationThirdPartyRelationHydraItem } from '../../../../../../composables/index.ts'
import { ThirdPartyNameEnum } from '../../../../../../composables/features/administrations/administrations.interface'

export const useExactInternalSettings = createGlobalState(() => {
  const loading = ref(false)
  const thirdPartyRelations = ref<AdministrationThirdPartyRelationHydraItem[]>([])
  const { onError } = useServerErrorHandler()
  const getInternalExactAdministrationThirdPartyRelations = async () => {
    if (loading.value) {
      return
    }
    try {
      const { data } = await api.get<HydraCollection<AdministrationThirdPartyRelationHydraItem>>(`/api/administration_third_party_relations?thirdPartyName=${ThirdPartyNameEnum.EXACT_INTERNAL}`)
      thirdPartyRelations.value = data['hydra:member']
    }
    catch (error) {
      onError(error)
    }
    finally {
      loading.value = false
    }
  }
  return {
    loading,
    thirdPartyRelations,
    getInternalExactAdministrationThirdPartyRelations,
  }
})
