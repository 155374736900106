import type { HydraContext } from '../../../common'

export enum PermissionScopeEnum {
  GLOBAL = 1,
  TENANT = 2,
  ORGANIZATION = 3,
  INDIVIDUAL = 4,
  CLIENT = 5,
  THIRD_PARTY = 6,
  ADMINISTRATION = 7,
  CASE_FILE_LIST = 8,
}

export enum Permission {
  CASEFILE_READ_COLLECTION = 'PERMISSION_CASE-FILE_READ_COLLECTION',
  CASEFILE_READ_SINGLE = 'PERMISSION_CASE-FILE_READ_SINGLE',
  CASEFILE_READ_ANY = 'PERMISSION_CASE-FILE_READ_ANY',
  CASEFILE_CREATE = 'PERMISSION_CASE-FILE_CREATE',
  CASEFILE_CREATE_ANY = 'PERMISSION_CASE-FILE_CREATE-ANY',
  CASEFILE_UPDATE = 'PERMISSION_CASE-FILE_UPDATE',
  CASEFILE_UPDATE_ANY = 'PERMISSION_CASE-FILE_UPDATE-ANY',
  CASEFILE_DELETE = 'PERMISSION_CASE-FILE_DELETE',
  CASEFILE_DELETE_ANY = 'PERMISSION_CASE-FILE_DELETE-ANY',
  CLIENT_READ_COLLECTION = 'PERMISSION_CLIENT_READ_COLLECTION',
  CLIENT_READ_SINGLE = 'PERMISSION_CLIENT_READ_SINGLE',
  CLIENT_READ_ANY = 'PERMISSION_CLIENT_READ_ANY',
  CLIENT_CREATE = 'PERMISSION_CLIENT_CREATE',
  CLIENT_CREATE_ANY = 'PERMISSION_CLIENT_CREATE-ANY',
  CLIENT_UPDATE = 'PERMISSION_CLIENT_UPDATE',
  CLIENT_UPDATE_ANY = 'PERMISSION_CLIENT_UPDATE-ANY',
  CLIENT_DELETE = 'PERMISSION_CLIENT_DELETE',
  CLIENT_DELETE_ANY = 'PERMISSION_CLIENT_DELETE-ANY',
}

export const PermissionResources = {
  ACTIVITY: 'ACTIVITY',
  ACTIVITY_GENERIC_TYPE: 'ACTIVITY-GENERIC-TYPE',
  ADDRESS: 'ADDRESS',
  ADMINISTRATION: 'ADMINISTRATION',
  ADMINISTRATION_SETTING: 'ADMINISTRATION-SETTING',
  APP: 'APP',
  APP_SUBSCRIPTION: 'APP-SUBSCRIPTION',
  AUTOMATION: 'AUTOMATION',
  CASE_FILE: 'CASE-FILE',
  CASE_FILE_CLOSE_REASON: 'CASE-FILE-CLOSE-REASON',
  CASE_FILE_DEBTOR_EXTRA_COST: 'CASE-FILE-DEBTOR-EXTRA-COST',
  CASE_FILE_DEBTOR_INVOICE: 'CASE-FILE-DEBTOR-INVOICE',
  CASE_FILE_LIST: 'CASE-FILE-LIST',
  CASE_FILE_LIST_CASE_FILE: 'CASE-FILE-LIST-CASE-FILE',
  CASE_FILE_SETTLEMENT: 'CASE-FILE-SETTLEMENT',
  CASE_FILE_STATUS: 'CASE-FILE-STATUS',
  CASE_FILE_THIRD_PARTY: 'CASE-FILE-THIRD-PARTY',
  CLIENT: 'CLIENT',
  CLIENT_COMMISSION_SETTING: 'CLIENT-COMMISSION-SETTING',
  CLIENT_SETTING: 'CLIENT-SETTING',
  COLLECTION_COST_GROUP: 'COLLECTION-COST-GROUP',
  COLLECTION_COST_GROUP_SETTING: 'COLLECTION-COST-GROUP-SETTING',
  CORRESPONDENCE_TEMPLATE_CONTENT_IDENTIFIER: 'CORRESPONDENCE-TEMPLATE-CONTENT-IDENTIFIER',
  CORRESPONDENCE_TEMPLATE_MARKUP: 'CORRESPONDENCE-TEMPLATE-MARKUP',
  CUSTOM_EXPORT: 'CUSTOM-EXPORT',
  EMAIL_EVENT_LOG: 'EMAIL-EVENT-LOG',
  EMAIL_QUEUE_JOB: 'EMAIL-QUEUE-JOB',
  EMAIL_TEMPLATE: 'EMAIL-TEMPLATE',
  FINANCIAL_ACCOUNT: 'FINANCIAL-ACCOUNT',
  FINANCIAL_ACCOUNT_PROCESS_SETTING: 'FINANCIAL-ACCOUNT-PROCESS-SETTING',
  FINANCIAL_ACCOUNT_SETTING: 'FINANCIAL-ACCOUNT-SETTING',
  PAYOUT_BATCH: 'PAYOUT-BATCH',
  GENERAL_LEDGER: 'GENERAL-LEDGER',
  INDIVIDUAL: 'INDIVIDUAL',
  INDIVIDUAL_LOGIN: 'INDIVIDUAL-LOGIN',
  INTEREST_RATE_GROUP: 'INTEREST-RATE-GROUP',
  INTEREST_RATE_SETTING: 'INTEREST-RATE-SETTING',
  INTERNAL_APP_CHAMBER_OF_COMMERCE: 'INTERNAL-APP-CHAMBER-OF-COMMERCE',
  INTERNAL_APP_EXACT: 'INTERNAL-APP-EXACT',
  INVOICE: 'INVOICE',
  INVOICE_LINE: 'INVOICE-LINE',
  MEDIA_FOLDER: 'MEDIA-FOLDER',
  MEDIA_OBJECT: 'MEDIA-OBJECT',
  MEDIA_OBJECT_CASE_FILE_META_DATA: 'MEDIA-OBJECT-CASE-FILE-META-DATA',
  CORRESPONDENCE_TEMPLATE_MARKUP_MEDIA_OBJECT: 'CORRESPONDENCE-TEMPLATE-MARKUP-MEDIA-OBJECT',
  PROFILE_PICTURE_MEDIA_OBJECT: 'PROFILE-PICTURE-MEDIA-OBJECT',
  MEDIA_OBJECT_SORT: 'MEDIA-OBJECT-SORT',
  USER_PICTURE_MEDIA_OBJECT: 'USER-PICTURE-MEDIA-OBJECT',
  NOTIFICATION_LINK: 'NOTIFICATION-LINK',
  NUMBER_SEQUENCE: 'NUMBER-SEQUENCE',
  ORGANIZATION: 'ORGANIZATION',
  PAYMENT: 'PAYMENT',
  PAYMENT_PLAN_GROUP: 'PAYMENT-PLAN-GROUP',
  PAYOUT: 'PAYOUT',
  PDF_TEMPLATE: 'PDF-TEMPLATE',
  PERMISSION_RESOURCE: 'PERMISSION-RESOURCE',
  PERMISSION_TEMPLATE_BRACKET: 'PERMISSION-TEMPLATE-BRACKET',
  PERMISSION_TEMPLATE_GROUP: 'PERMISSION-TEMPLATE-GROUP',
  PRINT_QUEUE_JOB: 'PRINT-QUEUE-JOB',
  PRODUCT: 'PRODUCT',
  SMS_EVENT_LOG: 'SMS-EVENT-LOG',
  SMS_QUEUE_JOB: 'SMS-QUEUE-JOB',
  STATISTIC: 'STATISTIC',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_LINE: 'SUBSCRIPTION-LINE',
  TASK_ASSIGNEE: 'TASK-ASSIGNEE',
  TASK: 'TASK',
  TASK_LIST: 'TASK-LIST',
  TASK_LIST_TASK: 'TASK-LIST-TASK',
  TENANT: 'TENANT',
  TENANT_SETTING: 'TENANT-SETTING',
  TEXT_TEMPLATE: 'TEXT-TEMPLATE',
  THIRD_PARTY: 'THIRD-PARTY',
  THIRD_PARTY_SORT: 'THIRD-PARTY-SORT',
  UNIT: 'UNIT',
  USER: 'USER',
  USER_BOOKMARK: 'USER-BOOKMARK',
  USER_PERMISSION: 'USER-PERMISSION',
}

export const PermissionAttributes = {
  GLOBAL: {
    '*': 'GLOBAL/*',
    'OPERATION': {
      '*': 'GLOBAL/OPERATION/*',
      'ACTIVITY': {
        '*': 'GLOBAL/OPERATION/ACTIVITY/*',
        'READ_ANY': 'GLOBAL/OPERATION/ACTIVITY/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/ACTIVITY/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/ACTIVITY/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/ACTIVITY/DELETE-ANY',
      },
      'ACTIVITY_GENERIC_TYPE': {
        '*': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/ACTIVITY-GENERIC-TYPE/DELETE',
      },
      'ADDRESS': {
        '*': 'GLOBAL/OPERATION/ADDRESS/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/ADDRESS/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/ADDRESS/READ-SINGLE',
        'LOOKUP': 'GLOBAL/OPERATION/ADDRESS/LOOKUP',
      },
      'ADMINISTRATION': {
        '*': 'GLOBAL/OPERATION/ADMINISTRATION/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/ADMINISTRATION/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/ADMINISTRATION/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/ADMINISTRATION/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/ADMINISTRATION/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/ADMINISTRATION/DELETE',
      },
      'ADMINISTRATION_SETTING': {
        '*': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/READ-GLOBAL',
        'READ_VIA_ADMINISTRATION': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/READ-VIA-ADMINISTRATION',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/ADMINISTRATION-SETTING/DELETE',
      },
      'APP': {
        '*': 'GLOBAL/OPERATION/APP/*',
        'READ_INTERNAL': 'GLOBAL/OPERATION/APP/READ-INTERNAL',
        'READ_ANY': 'GLOBAL/OPERATION/APP/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/APP/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/APP/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/APP/DELETE-ANY',
      },
      'APP_SUBSCRIPTION': {
        '*': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/*',
        'SETUP_ANY': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/SETUP-ANY',
        'READ_ANY': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/DELETE-ANY',
        'SETUP': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/SETUP',
        'READ_SINGLE': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/APP-SUBSCRIPTION/DELETE',
      },
      'AUTOMATION': {
        '*': 'GLOBAL/OPERATION/AUTOMATION/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/AUTOMATION/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/AUTOMATION/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/AUTOMATION/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/AUTOMATION/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/AUTOMATION/DELETE',
      },
      'CASE_FILE': {
        '*': 'GLOBAL/OPERATION/CASE-FILE/*',
        'REMIT': 'GLOBAL/OPERATION/CASE-FILE/REMIT',
        'READ_COLLECTION_VIA_LIST': 'GLOBAL/OPERATION/CASE-FILE/READ-COLLECTION-VIA-LIST',
        'FORCE_RECALCULATE_INTEREST': 'GLOBAL/OPERATION/CASE-FILE/FORCE-RECALCULATE-INTEREST',
        'FORCE_RECALCULATE_COLLECTION_COSTS': 'GLOBAL/OPERATION/CASE-FILE/FORCE-RECALCULATE-COLLECTION-COSTS',
        'FORCE_SYNC_SETTINGS': 'GLOBAL/OPERATION/CASE-FILE/FORCE-SYNC-SETTINGS',
        'READ_ANY': 'GLOBAL/OPERATION/CASE-FILE/READ-ANY',
        'READ_COLLECTION_VIA_ADDRESS_DEBTOR': 'GLOBAL/OPERATION/CASE-FILE/READ-COLLECTION-VIA-ADDRESS-DEBTOR',
        'READ_COLLECTION_VIA_ADDRESS_CLIENT': 'GLOBAL/OPERATION/CASE-FILE/READ-COLLECTION-VIA-ADDRESS-CLIENT',
        'READ_COLLECTION': 'GLOBAL/OPERATION/CASE-FILE/READ-COLLECTION',
        'CREATE_ANY': 'GLOBAL/OPERATION/CASE-FILE/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/CASE-FILE/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/CASE-FILE/DELETE-ANY',
        'READ_PAYMENT_LINK': 'GLOBAL/OPERATION/CASE-FILE/READ-PAYMENT-LINK',
        'FORCE_SYNC_FINANCIAL_STATE': 'GLOBAL/OPERATION/CASE-FILE/FORCE-SYNC-FINANCIAL-STATE',
      },
      'CASE_FILE_CLOSE_REASON': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CASE-FILE-CLOSE-REASON/DELETE',
      },
      'CASE_FILE_DEBTOR_EXTRA_COST': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/*',
        'READ_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/DELETE-ANY',
      },
      'CASE_FILE_DEBTOR_INVOICE': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-INVOICE/*',
        'READ_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-INVOICE/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-INVOICE/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-INVOICE/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/CASE-FILE-DEBTOR-INVOICE/DELETE-ANY',
      },
      'CASE_FILE_LIST': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-LIST/*',
        'READ_ANY': 'GLOBAL/OPERATION/CASE-FILE-LIST/READ-ANY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/CASE-FILE-LIST/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/CASE-FILE-LIST/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/CASE-FILE-LIST/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CASE-FILE-LIST/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CASE-FILE-LIST/DELETE',
      },
      'CASE_FILE_LIST_CASE_FILE': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-LIST-CASE-FILE/*',
        'CREATE': 'GLOBAL/OPERATION/CASE-FILE-LIST-CASE-FILE/CREATE',
        'DELETE': 'GLOBAL/OPERATION/CASE-FILE-LIST-CASE-FILE/DELETE',
      },
      'CASE_FILE_SETTLEMENT': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-SETTLEMENT/*',
        'READ_ANY': 'GLOBAL/OPERATION/CASE-FILE-SETTLEMENT/READ-ANY',
        'UPSERT_ANY': 'GLOBAL/OPERATION/CASE-FILE-SETTLEMENT/UPSERT-ANY',
      },
      'CASE_FILE_STATUS': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-STATUS/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/CASE-FILE-STATUS/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/CASE-FILE-STATUS/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/CASE-FILE-STATUS/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CASE-FILE-STATUS/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CASE-FILE-STATUS/DELETE',
      },
      'CASE_FILE_THIRD_PARTY': {
        '*': 'GLOBAL/OPERATION/CASE-FILE-THIRD-PARTY/*',
        'CREATE': 'GLOBAL/OPERATION/CASE-FILE-THIRD-PARTY/CREATE',
        'DELETE': 'GLOBAL/OPERATION/CASE-FILE-THIRD-PARTY/DELETE',
      },
      'CLIENT': {
        '*': 'GLOBAL/OPERATION/CLIENT/*',
        'READ_ANY': 'GLOBAL/OPERATION/CLIENT/READ-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/CLIENT/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/CLIENT/DELETE-ANY',
        'CREATE': 'GLOBAL/OPERATION/CLIENT/CREATE',
      },
      'CLIENT_COMMISSION_SETTING': {
        '*': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/READ-GLOBAL',
        'READ_VIA_CLIENT': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/READ-VIA-CLIENT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CLIENT-COMMISSION-SETTING/DELETE',
      },
      'CLIENT_SETTING': {
        '*': 'GLOBAL/OPERATION/CLIENT-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/CLIENT-SETTING/READ-GLOBAL',
        'READ_VIA_CLIENT': 'GLOBAL/OPERATION/CLIENT-SETTING/READ-VIA-CLIENT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/CLIENT-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/CLIENT-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CLIENT-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CLIENT-SETTING/DELETE',
      },
      'COLLECTION_COST_GROUP': {
        '*': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/*',
        'READ_ANY': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/READ-ANY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/READ-COLLECTION',
        'READ_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/READ-GLOBAL',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/DELETE-GLOBAL',
        'CREATE_ANY': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP/DELETE-ANY',
      },
      'COLLECTION_COST_GROUP_SETTING': {
        '*': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/READ-GLOBAL',
        'READ_VIA_CLIENT': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/READ-VIA-CLIENT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/COLLECTION-COST-GROUP-SETTING/DELETE',
      },
      'CORRESPONDENCE_TEMPLATE_CONTENT_IDENTIFIER': {
        '*': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-CONTENT-IDENTIFIER/*',
        'READ_COLLECTION_VIA_CORRESPONDENCE_TEMPLATE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-CONTENT-IDENTIFIER/READ-COLLECTION-VIA-CORRESPONDENCE-TEMPLATE',
      },
      'CORRESPONDENCE_TEMPLATE_MARKUP': {
        '*': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP/*',
        'CREATE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP/DELETE',
      },
      'CUSTOM_EXPORT': {
        '*': 'GLOBAL/OPERATION/CUSTOM-EXPORT/*',
        'READ_ANY': 'GLOBAL/OPERATION/CUSTOM-EXPORT/READ-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/CUSTOM-EXPORT/DELETE-ANY',
        'CREATE_CSV_EXPORT': 'GLOBAL/OPERATION/CUSTOM-EXPORT/CREATE-CSV-EXPORT',
        'READ_COLLECTION_VIA_USER': 'GLOBAL/OPERATION/CUSTOM-EXPORT/READ-COLLECTION-VIA-USER',
        'READ_SINGLE': 'GLOBAL/OPERATION/CUSTOM-EXPORT/READ-SINGLE',
        'DELETE': 'GLOBAL/OPERATION/CUSTOM-EXPORT/DELETE',
      },
      'EMAIL_EVENT_LOG': {
        '*': 'GLOBAL/OPERATION/EMAIL-EVENT-LOG/*',
        'READ_COLLECTION_VIA_SEND_EMAIL_QUEUE_ITEM': 'GLOBAL/OPERATION/EMAIL-EVENT-LOG/READ-COLLECTION-VIA-SEND-EMAIL-QUEUE-ITEM',
      },
      'EMAIL_QUEUE_JOB': {
        '*': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/DELETE',
        'EXECUTE': 'GLOBAL/OPERATION/EMAIL-QUEUE-JOB/EXECUTE',
      },
      'EMAIL_TEMPLATE': {
        '*': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/EMAIL-TEMPLATE/DELETE',
      },
      'FINANCIAL_ACCOUNT': {
        '*': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT/DELETE',
      },
      'FINANCIAL_ACCOUNT_PROCESS_SETTING': {
        '*': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/READ-GLOBAL',
        'READ_VIA_FINANCIAL_ACCOUNT': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/READ-VIA-FINANCIAL-ACCOUNT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-PROCESS-SETTING/DELETE',
      },
      'FINANCIAL_ACCOUNT_SETTING': {
        '*': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/READ-GLOBAL',
        'READ_VIA_FINANCIAL_ACCOUNT': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/READ-VIA-FINANCIAL-ACCOUNT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/FINANCIAL-ACCOUNT-SETTING/DELETE',
      },
      'PAYOUT_BATCH': {
        '*': 'GLOBAL/OPERATION/PAYOUT-BATCH/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PAYOUT-BATCH/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PAYOUT-BATCH/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PAYOUT-BATCH/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PAYOUT-BATCH/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PAYOUT-BATCH/DELETE',
      },
      'GENERAL_LEDGER': {
        '*': 'GLOBAL/OPERATION/GENERAL-LEDGER/*',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'GLOBAL/OPERATION/GENERAL-LEDGER/READ-COLLECTION-VIA-ADMINISTRATION',
        'READ_SINGLE': 'GLOBAL/OPERATION/GENERAL-LEDGER/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/GENERAL-LEDGER/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/GENERAL-LEDGER/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/GENERAL-LEDGER/DELETE',
      },
      'INDIVIDUAL': {
        '*': 'GLOBAL/OPERATION/INDIVIDUAL/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/INDIVIDUAL/READ-COLLECTION',
        'READ_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/DELETE-ANY',
        'CREATE_LOGIN_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/CREATE-LOGIN-ANY',
        'DELETE_LOGIN_ANY': 'GLOBAL/OPERATION/INDIVIDUAL/DELETE-LOGIN-ANY',
      },
      'INDIVIDUAL_LOGIN': {
        '*': 'GLOBAL/OPERATION/INDIVIDUAL-LOGIN/*',
        'CREATE_LOGIN_ANY': 'GLOBAL/OPERATION/INDIVIDUAL-LOGIN/CREATE-LOGIN-ANY',
        'DELETE_LOGIN_ANY': 'GLOBAL/OPERATION/INDIVIDUAL-LOGIN/DELETE-LOGIN-ANY',
      },
      'INTEREST_RATE_GROUP': {
        '*': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/*',
        'READ_ANY': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/READ-ANY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/READ-COLLECTION',
        'READ_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/READ-GLOBAL',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/DELETE-GLOBAL',
        'CREATE_ANY': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/INTEREST-RATE-GROUP/DELETE-ANY',
      },
      'INTEREST_RATE_SETTING': {
        '*': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/*',
        'READ_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/READ-GLOBAL',
        'READ_VIA_CLIENT': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/READ-VIA-CLIENT',
        'CREATE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/CREATE-GLOBAL',
        'UPDATE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/UPDATE-GLOBAL',
        'DELETE_GLOBAL': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/DELETE-GLOBAL',
        'CREATE': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/INTEREST-RATE-SETTING/DELETE',
      },
      'INTERNAL_APP_CHAMBER_OF_COMMERCE': {
        '*': 'GLOBAL/OPERATION/INTERNAL-APP-CHAMBER-OF-COMMERCE/*',
        'SEARCH_LOOKUP': 'GLOBAL/OPERATION/INTERNAL-APP-CHAMBER-OF-COMMERCE/SEARCH-LOOKUP',
      },
      'INTERNAL_APP_EXACT': {
        '*': 'GLOBAL/OPERATION/INTERNAL-APP-EXACT/*',
        'READ_DIVISIONS': 'GLOBAL/OPERATION/INTERNAL-APP-EXACT/READ-DIVISIONS',
        'READ_GL_ACCOUNTS': 'GLOBAL/OPERATION/INTERNAL-APP-EXACT/READ-GL-ACCOUNTS',
        'READ_VAT_CODES': 'GLOBAL/OPERATION/INTERNAL-APP-EXACT/READ-VAT-CODES',
      },
      'INVOICE': {
        '*': 'GLOBAL/OPERATION/INVOICE/*',
        'READ_ANY': 'GLOBAL/OPERATION/INVOICE/READ-ANY',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'GLOBAL/OPERATION/INVOICE/READ-COLLECTION-VIA-ADMINISTRATION',
        'READ_COLLECTION': 'GLOBAL/OPERATION/INVOICE/READ-COLLECTION',
        'CREATE': 'GLOBAL/OPERATION/INVOICE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/INVOICE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/INVOICE/DELETE',
      },
      'INVOICE_LINE': {
        '*': 'GLOBAL/OPERATION/INVOICE-LINE/*',
        'READ_ANY': 'GLOBAL/OPERATION/INVOICE-LINE/READ-ANY',
        'READ_SINGLE': 'GLOBAL/OPERATION/INVOICE-LINE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/INVOICE-LINE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/INVOICE-LINE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/INVOICE-LINE/DELETE',
      },
      'MEDIA_FOLDER': {
        '*': 'GLOBAL/OPERATION/MEDIA-FOLDER/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/MEDIA-FOLDER/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/MEDIA-FOLDER/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/MEDIA-FOLDER/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/MEDIA-FOLDER/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/MEDIA-FOLDER/DELETE',
      },
      'MEDIA_OBJECT': {
        '*': 'GLOBAL/OPERATION/MEDIA-OBJECT/*',
        'READ_COLLECTION_VIA_CORRESPONDENCE_TEMPLATE_MARKUP': 'GLOBAL/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-CORRESPONDENCE-TEMPLATE-MARKUP',
        'READ_COLLECTION_VIA_PAYOUT_BATCH': 'GLOBAL/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-PAYOUT-BATCH',
        'READ_COLLECTION_VIA_CUSTOM_EXPORT': 'GLOBAL/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-CUSTOM-EXPORT',
        'READ_COLLECTION_VIA_MEDIA_FOLDER': 'GLOBAL/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-MEDIA-FOLDER',
        'READ_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT/CREATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT/DELETE-ANY',
      },
      'MEDIA_OBJECT_CASE_FILE_META_DATA': {
        '*': 'GLOBAL/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/*',
        'CREATE_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/DELETE-ANY',
      },
      'CORRESPONDENCE_TEMPLATE_MARKUP_MEDIA_OBJECT': {
        '*': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP-MEDIA-OBJECT/*',
        'CREATE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP-MEDIA-OBJECT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP-MEDIA-OBJECT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/CORRESPONDENCE-TEMPLATE-MARKUP-MEDIA-OBJECT/DELETE',
      },
      'PROFILE_PICTURE_MEDIA_OBJECT': {
        '*': 'GLOBAL/OPERATION/PROFILE-PICTURE-MEDIA-OBJECT/*',
        'CREATE': 'GLOBAL/OPERATION/PROFILE-PICTURE-MEDIA-OBJECT/CREATE',
        'DELETE': 'GLOBAL/OPERATION/PROFILE-PICTURE-MEDIA-OBJECT/DELETE',
      },
      'PROFILE_ADDITIONAL_CONTACT_INFORMATION': {
        '*': 'GLOBAL/OPERATION/PROFILE-ADDITIONAL-CONTACT-INFORMATION/*',
        'CREATE': 'GLOBAL/OPERATION/PROFILE-ADDITIONAL-CONTACT-INFORMATION/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PROFILE-ADDITIONAL-CONTACT-INFORMATION/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PROFILE-ADDITIONAL-CONTACT-INFORMATION/DELETE',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PROFILE-ADDITIONAL-CONTACT-INFORMATION/READ-COLLECTION',
      },
      'MEDIA_OBJECT_SORT': {
        '*': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/MEDIA-OBJECT-SORT/DELETE',
      },
      'USER_PICTURE_MEDIA_OBJECT': {
        '*': 'GLOBAL/OPERATION/USER-PICTURE-MEDIA-OBJECT/*',
        'CREATE': 'GLOBAL/OPERATION/USER-PICTURE-MEDIA-OBJECT/CREATE',
        'DELETE': 'GLOBAL/OPERATION/USER-PICTURE-MEDIA-OBJECT/DELETE',
      },
      'NOTIFICATION_LINK': {
        '*': 'GLOBAL/OPERATION/NOTIFICATION-LINK/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/NOTIFICATION-LINK/READ-COLLECTION',
      },
      'NUMBER_SEQUENCE': {
        '*': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/NUMBER-SEQUENCE/DELETE',
      },
      'ORGANIZATION': {
        '*': 'GLOBAL/OPERATION/ORGANIZATION/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/ORGANIZATION/READ-COLLECTION',
        'READ_ANY': 'GLOBAL/OPERATION/ORGANIZATION/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/ORGANIZATION/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/ORGANIZATION/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/ORGANIZATION/DELETE-ANY',
      },
      'PAYMENT': {
        '*': 'GLOBAL/OPERATION/PAYMENT/*',
        'READ_ANY': 'GLOBAL/OPERATION/PAYMENT/READ-ANY',
        'CONFIRM_ANY': 'GLOBAL/OPERATION/PAYMENT/CONFIRM-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/PAYMENT/CREATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/PAYMENT/DELETE-ANY',
      },
      'PAYMENT_PLAN_GROUP': {
        '*': 'GLOBAL/OPERATION/PAYMENT-PLAN-GROUP/*',
        'READ_ANY': 'GLOBAL/OPERATION/PAYMENT-PLAN-GROUP/READ-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/PAYMENT-PLAN-GROUP/DELETE-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/PAYMENT-PLAN-GROUP/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/PAYMENT-PLAN-GROUP/UPDATE-ANY',
      },
      'PAYOUT': {
        '*': 'GLOBAL/OPERATION/PAYOUT/*',
        'READ_ANY': 'GLOBAL/OPERATION/PAYOUT/READ-ANY',
        'READ_COLLECTION_VIA_PAYOUT': 'GLOBAL/OPERATION/PAYOUT/READ-COLLECTION-VIA-PAYOUT',
        'READ_COLLECTION_VIA_PAYOUT_BATCH': 'GLOBAL/OPERATION/PAYOUT/READ-COLLECTION-VIA-PAYOUT-BATCH',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PAYOUT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PAYOUT/READ-SINGLE',
        'TOGGLE_BLOCK': 'GLOBAL/OPERATION/PAYOUT/TOGGLE-BLOCK',
        'BLOCK': 'GLOBAL/OPERATION/PAYOUT/BLOCK',
        'UNBLOCK': 'GLOBAL/OPERATION/PAYOUT/UNBLOCK',
        'DELETE': 'GLOBAL/OPERATION/PAYOUT/DELETE',
      },
      'PDF_TEMPLATE': {
        '*': 'GLOBAL/OPERATION/PDF-TEMPLATE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PDF-TEMPLATE/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PDF-TEMPLATE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PDF-TEMPLATE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PDF-TEMPLATE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PDF-TEMPLATE/DELETE',
      },
      'PERMISSION_RESOURCE': {
        '*': 'GLOBAL/OPERATION/PERMISSION-RESOURCE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PERMISSION-RESOURCE/READ-COLLECTION',
      },
      'PERMISSION_TEMPLATE_BRACKET': {
        '*': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-BRACKET/*',
        'READ_SINGLE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-BRACKET/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-BRACKET/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-BRACKET/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-BRACKET/DELETE',
      },
      'PERMISSION_TEMPLATE_GROUP': {
        '*': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/*',
        'READ_CONTEXT_ANY': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-CONTEXT-ANY',
        'READ_CONTEXT_CLIENT': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-CONTEXT-CLIENT',
        'READ_CONTEXT_DEBTOR': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-CONTEXT-DEBTOR',
        'READ_CONTEXT_THIRD_PARTY': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-CONTEXT-THIRD-PARTY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PERMISSION-TEMPLATE-GROUP/DELETE',
      },
      'PRINT_QUEUE_JOB': {
        '*': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/DELETE',
        'EXECUTE': 'GLOBAL/OPERATION/PRINT-QUEUE-JOB/EXECUTE',
      },
      'PRODUCT': {
        '*': 'GLOBAL/OPERATION/PRODUCT/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/PRODUCT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/PRODUCT/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/PRODUCT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/PRODUCT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/PRODUCT/DELETE',
      },
      'SMS_EVENT_LOG': {
        '*': 'GLOBAL/OPERATION/SMS-EVENT-LOG/*',
        'READ_COLLECTION_VIA_QUEUE_JOB': 'GLOBAL/OPERATION/SMS-EVENT-LOG/READ-COLLECTION-VIA-QUEUE-JOB',
      },
      'SMS_QUEUE_JOB': {
        '*': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/DELETE',
        'EXECUTE': 'GLOBAL/OPERATION/SMS-QUEUE-JOB/EXECUTE',
      },
      'STATISTIC': {
        '*': 'GLOBAL/OPERATION/STATISTIC/*',
        'READ_ANY_USER': 'GLOBAL/OPERATION/STATISTIC/READ-ANY-USER',
        'READ_SINGLE_USER': 'GLOBAL/OPERATION/STATISTIC/READ-SINGLE-USER',
        'READ_ANY_CLIENT': 'GLOBAL/OPERATION/STATISTIC/READ-ANY-CLIENT',
      },
      'SUBSCRIPTION': {
        '*': 'GLOBAL/OPERATION/SUBSCRIPTION/*',
        'READ_ANY': 'GLOBAL/OPERATION/SUBSCRIPTION/READ-ANY',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'GLOBAL/OPERATION/SUBSCRIPTION/READ-COLLECTION-VIA-ADMINISTRATION',
        'READ_COLLECTION': 'GLOBAL/OPERATION/SUBSCRIPTION/READ-COLLECTION',
        'CREATE': 'GLOBAL/OPERATION/SUBSCRIPTION/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/SUBSCRIPTION/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/SUBSCRIPTION/DELETE',
      },
      'SUBSCRIPTION_LINE': {
        '*': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/*',
        'READ_SINGLE': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/READ-SINGLE',
        'READ_ANY': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/READ-ANY',
        'CREATE': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/SUBSCRIPTION-LINE/DELETE',
      },
      'TASK_ASSIGNEE': {
        '*': 'GLOBAL/OPERATION/TASK-ASSIGNEE/*',
        'CREATE': 'GLOBAL/OPERATION/TASK-ASSIGNEE/CREATE',
        'DELETE': 'GLOBAL/OPERATION/TASK-ASSIGNEE/DELETE',
      },
      'TASK': {
        '*': 'GLOBAL/OPERATION/TASK/*',
        'READ_SINGLE': 'GLOBAL/OPERATION/TASK/READ-SINGLE',
        'READ_ANY': 'GLOBAL/OPERATION/TASK/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/TASK/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/TASK/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/TASK/DELETE-ANY',
        'CREATE': 'GLOBAL/OPERATION/TASK/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/TASK/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/TASK/DELETE',
        'UPDATE_BULK': 'GLOBAL/OPERATION/TASK/UPDATE-BULK',
        'READ_COLLECTION_VIA_CASE_FILE': 'GLOBAL/OPERATION/TASK/READ-COLLECTION-VIA-CASE-FILE',
        'READ_COLLECTION_VIA_USER': 'GLOBAL/OPERATION/TASK/READ-COLLECTION-VIA-USER',
        'READ_COLLECTION_VIA_TASK_LIST': 'GLOBAL/OPERATION/TASK/READ-COLLECTION-VIA-TASK-LIST',
      },
      'TASK_LIST': {
        '*': 'GLOBAL/OPERATION/TASK-LIST/*',
        'READ_ANY': 'GLOBAL/OPERATION/TASK-LIST/READ-ANY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/TASK-LIST/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/TASK-LIST/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/TASK-LIST/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/TASK-LIST/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/TASK-LIST/DELETE',
      },
      'TASK_LIST_TASK': {
        '*': 'GLOBAL/OPERATION/TASK-LIST-TASK/*',
        'CREATE': 'GLOBAL/OPERATION/TASK-LIST-TASK/CREATE',
        'DELETE': 'GLOBAL/OPERATION/TASK-LIST-TASK/DELETE',
      },
      'TENANT': {
        '*': 'GLOBAL/OPERATION/TENANT/*',
        'READ_ANY': 'GLOBAL/OPERATION/TENANT/READ-ANY',
        'CREATE': 'GLOBAL/OPERATION/TENANT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/TENANT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/TENANT/DELETE',
      },
      'TENANT_SETTING': {
        '*': 'GLOBAL/OPERATION/TENANT-SETTING/*',
        'READ_ANY': 'GLOBAL/OPERATION/TENANT-SETTING/READ-ANY',
        'CREATE_ANY': 'GLOBAL/OPERATION/TENANT-SETTING/CREATE-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/TENANT-SETTING/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/TENANT-SETTING/DELETE-ANY',
      },
      'TEXT_TEMPLATE': {
        '*': 'GLOBAL/OPERATION/TEXT-TEMPLATE/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/TEXT-TEMPLATE/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/TEXT-TEMPLATE/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/TEXT-TEMPLATE/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/TEXT-TEMPLATE/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/TEXT-TEMPLATE/DELETE',
      },
      'THIRD_PARTY': {
        '*': 'GLOBAL/OPERATION/THIRD-PARTY/*',
        'READ_ANY': 'GLOBAL/OPERATION/THIRD-PARTY/READ-ANY',
        'READ_COLLECTION': 'GLOBAL/OPERATION/THIRD-PARTY/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/THIRD-PARTY/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/THIRD-PARTY/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/THIRD-PARTY/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/THIRD-PARTY/DELETE',
      },
      'THIRD_PARTY_SORT': {
        '*': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/THIRD-PARTY-SORT/DELETE',
      },
      'UNIT': {
        '*': 'GLOBAL/OPERATION/UNIT/*',
        'READ_COLLECTION': 'GLOBAL/OPERATION/UNIT/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/UNIT/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/UNIT/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/UNIT/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/UNIT/DELETE',
      },
      'USER': {
        '*': 'GLOBAL/OPERATION/USER/*',
        'READ_COLLECTION_EMPLOYEES': 'GLOBAL/OPERATION/USER/READ-COLLECTION-EMPLOYEES',
        'READ_COLLECTION': 'GLOBAL/OPERATION/USER/READ-COLLECTION',
        'READ_SINGLE': 'GLOBAL/OPERATION/USER/READ-SINGLE',
        'CREATE': 'GLOBAL/OPERATION/USER/CREATE',
        'UPDATE': 'GLOBAL/OPERATION/USER/UPDATE',
        'DELETE': 'GLOBAL/OPERATION/USER/DELETE',
      },
      'USER_BOOKMARK': {
        '*': 'GLOBAL/OPERATION/USER-BOOKMARK/*',
        'CREATE': 'GLOBAL/OPERATION/USER-BOOKMARK/CREATE',
      },
      'USER_PERMISSION': {
        '*': 'GLOBAL/OPERATION/USER-PERMISSION/*',
        'READ_COLLECTION_VIA_USERS': 'GLOBAL/OPERATION/USER-PERMISSION/READ-COLLECTION-VIA-USERS',
        'CREATE': 'GLOBAL/OPERATION/USER-PERMISSION/CREATE',
        'READ_ANY': 'GLOBAL/OPERATION/USER-PERMISSION/READ-ANY',
        'UPDATE_ANY': 'GLOBAL/OPERATION/USER-PERMISSION/UPDATE-ANY',
        'DELETE_ANY': 'GLOBAL/OPERATION/USER-PERMISSION/DELETE-ANY',
      },
    },
    'FIELD': {
      '*': 'GLOBAL/FIELD/*',
      'CASE_FILE': {
        'COLLECTION_COST_GROUP': {
          '*': 'GLOBAL/FIELD/CASE-FILE/COLLECTION-COST-GROUP/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/COLLECTION-COST-GROUP/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/COLLECTION-COST-GROUP/UPDATE',
        },
        'CLOSE_REASON': {
          '*': 'GLOBAL/FIELD/CASE-FILE/CLOSE-REASON/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/CLOSE-REASON/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/CLOSE-REASON/UPDATE',
        },
        'CONFIRMED_AT': {
          '*': 'GLOBAL/FIELD/CASE-FILE/CONFIRMED-AT/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/CONFIRMED-AT/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/CONFIRMED-AT/UPDATE',
        },
        'STATUS': {
          '*': 'GLOBAL/FIELD/CASE-FILE/STATUS/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/STATUS/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/STATUS/UPDATE',
        },
        'ADMINISTRATION': {
          '*': 'GLOBAL/FIELD/CASE-FILE/ADMINISTRATION/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/ADMINISTRATION/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/ADMINISTRATION/UPDATE',
        },
        'SORT': {
          '*': 'GLOBAL/FIELD/CASE-FILE/SORT/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/SORT/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/SORT/UPDATE',
        },
        'DEBTOR': {
          '*': 'GLOBAL/FIELD/CASE-FILE/DEBTOR/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/DEBTOR/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/DEBTOR/UPDATE',
        },
        'EXECUTOR': {
          '*': 'GLOBAL/FIELD/CASE-FILE/EXECUTOR/*',
          'CREATE': 'GLOBAL/FIELD/CASE-FILE/EXECUTOR/CREATE',
          'UPDATE': 'GLOBAL/FIELD/CASE-FILE/EXECUTOR/UPDATE',
        },
        '*': 'GLOBAL/FIELD/CASE-FILE/*',
        'CREDITOR': {
          CREATE: 'GLOBAL/FIELD/CASE-FILE/CREDITOR/CREATE',
          UPDATE: 'GLOBAL/FIELD/CASE-FILE/CREDITOR/UPDATE',
        },
        'AUTO_CONFIRM': 'GLOBAL/FIELD/CASE-FILE/AUTO-CONFIRM',
      },
      'CASE_FILE_DEBTOR_EXTRA_COST': {
        '*': 'GLOBAL/FIELD/CASE-FILE-DEBTOR-EXTRA-COST/*',
        'READ_GENERAL_LEDGER': 'GLOBAL/FIELD/CASE-FILE-DEBTOR-EXTRA-COST/READ-GENERAL-LEDGER',
      },
      'FINANCIAL_ACCOUNT': {
        '*': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/*',
        'BALANCE': {
          '*': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BALANCE/*',
          'READ': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BALANCE/READ',
        },
        'CLIENT': {
          '*': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/CLIENT/*',
          'CREATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/CLIENT/CREATE',
          'UPDATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/CLIENT/UPDATE',
          'READ': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/CLIENT/READ',
        },
        'BIC': {
          '*': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BIC/*',
          'CREATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BIC/CREATE',
          'UPDATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BIC/UPDATE',
          'READ': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/BIC/READ',
        },
        'IBAN': {
          '*': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/IBAN/*',
          'CREATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/IBAN/CREATE',
          'UPDATE': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/IBAN/UPDATE',
          'READ': 'GLOBAL/FIELD/FINANCIAL-ACCOUNT/IBAN/READ',
        },
      },
      'INVOICE': {
        '*': 'GLOBAL/FIELD/INVOICE/*',
        'PDF_TEMPLATE': {
          '*': 'GLOBAL/FIELD/INVOICE/PDF-TEMPLATE/*',
          'CREATE': 'GLOBAL/FIELD/INVOICE/PDF-TEMPLATE/CREATE',
          'UPDATE': 'GLOBAL/FIELD/INVOICE/PDF-TEMPLATE/UPDATE',
          'READ': 'GLOBAL/FIELD/INVOICE/PDF-TEMPLATE/READ',
        },
        'EMAIL_TEMPLATE': {
          '*': 'GLOBAL/FIELD/INVOICE/EMAIL-TEMPLATE/*',
          'CREATE': 'GLOBAL/FIELD/INVOICE/EMAIL-TEMPLATE/CREATE',
          'UPDATE': 'GLOBAL/FIELD/INVOICE/EMAIL-TEMPLATE/UPDATE',
          'READ': 'GLOBAL/FIELD/INVOICE/EMAIL-TEMPLATE/READ',
        },
      },
      'INVOICE_LINE': {
        '*': 'GLOBAL/FIELD/INVOICE-LINE/*',
        'GENERAL_LEDGER': {
          '*': 'GLOBAL/FIELD/INVOICE-LINE/GENERAL-LEDGER/*',
          'CREATE': 'GLOBAL/FIELD/INVOICE-LINE/GENERAL-LEDGER/CREATE',
          'UPDATE': 'GLOBAL/FIELD/INVOICE-LINE/GENERAL-LEDGER/UPDATE',
          'READ': 'GLOBAL/FIELD/INVOICE-LINE/GENERAL-LEDGER/READ',
        },
      },
      'PAYMENT': {
        '*': 'GLOBAL/FIELD/PAYMENT/*',
      },
      'PAYOUT': {
        '*': 'GLOBAL/FIELD/PAYOUT/*',
        'ACCOUNT_NAME': {
          '*': 'GLOBAL/FIELD/PAYOUT/ACCOUNT-NAME/*',
          'READ': 'GLOBAL/FIELD/PAYOUT/ACCOUNT-NAME/READ',
        },
        'ACCOUNT_IBAN': {
          '*': 'GLOBAL/FIELD/PAYOUT/ACCOUNT-IBAN/*',
          'READ': 'GLOBAL/FIELD/PAYOUT/ACCOUNT-IBAN/READ',
        },
        'TRANSFERRED_BY': {
          '*': 'GLOBAL/FIELD/PAYOUT/TRANSFERRED-BY/*',
          'READ': 'GLOBAL/FIELD/PAYOUT/TRANSFERRED-BY/READ',
        },
        'BLOCKED_BY': {
          '*': 'GLOBAL/FIELD/PAYOUT/BLOCKED-BY/*',
          'READ': 'GLOBAL/FIELD/PAYOUT/BLOCKED-BY/READ',
        },
        'BLOCKED_AT': {
          '*': 'GLOBAL/FIELD/PAYOUT/BLOCKED-AT/*',
          'READ': 'GLOBAL/FIELD/PAYOUT/BLOCKED-AT/READ',
        },
      },
      'USER': {
        'ALLOWED_IP_ADDRESSES': {
          '*': 'GLOBAL/FIELD/USER/ALLOWED-IP-ADDRESSES/*',
          'READ': 'GLOBAL/FIELD/USER/ALLOWED-IP-ADDRESSES/READ',
          'CREATE': 'GLOBAL/FIELD/USER/ALLOWED-IP-ADDRESSES/CREATE',
          'UPDATE': 'GLOBAL/FIELD/USER/ALLOWED-IP-ADDRESSES/UPDATE',
        },
        'ROLES': {
          '*': 'GLOBAL/FIELD/USER/ROLES/*',
          'CREATE': 'GLOBAL/FIELD/USER/ROLES/CREATE',
          'UPDATE': 'GLOBAL/FIELD/USER/ROLES/UPDATE',
        },
        '*': 'GLOBAL/FIELD/USER/*',
      },
    },
  },
  CONTEXT: {
    '*': 'CONTEXT/*',
    'OPERATION': {
      '*': 'CONTEXT/OPERATION/*',
      'APP': {
        '*': 'CONTEXT/OPERATION/APP/*',
        'READ_SINGLE': 'CONTEXT/OPERATION/APP/READ-SINGLE',
        'CREATE': 'CONTEXT/OPERATION/APP/CREATE',
        'UPDATE': 'CONTEXT/OPERATION/APP/UPDATE',
        'DELETE': 'CONTEXT/OPERATION/APP/DELETE',
      },
      'CASE_FILE': {
        '*': 'CONTEXT/OPERATION/CASE-FILE/*',
        'CREATE_BULK': 'CONTEXT/OPERATION/CASE-FILE/CREATE-BULK',
        'UPDATE_BULK': 'CONTEXT/OPERATION/CASE-FILE/UPDATE-BULK',
      },
      'CASE_FILE_LIST': {
        '*': 'CONTEXT/OPERATION/CASE-FILE-LIST/*',
        'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/OPERATION/CASE-FILE-LIST/READ-COLLECTION-VIA-CASE-FILE',
      },
      'MEDIA_OBJECT': {
        '*': 'CONTEXT/OPERATION/MEDIA-OBJECT/*',
        'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-CASE-FILE',
        'READ_COLLECTION_VIA_PROFILE': 'CONTEXT/OPERATION/MEDIA-OBJECT/READ-COLLECTION-VIA-PROFILE',
        'READ_SINGLE': 'CONTEXT/OPERATION/MEDIA-OBJECT/READ-SINGLE',
        'CREATE': 'CONTEXT/OPERATION/MEDIA-OBJECT/CREATE',
        'DELETE': 'CONTEXT/OPERATION/MEDIA-OBJECT/DELETE',
      },
      'MEDIA_OBJECT_CASE_FILE_META_DATA': {
        '*': 'CONTEXT/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/*',
        'CREATE': 'CONTEXT/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/CREATE',
        'UPDATE': 'CONTEXT/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/UPDATE',
        'DELETE': 'CONTEXT/OPERATION/MEDIA-OBJECT-CASE-FILE-META-DATA/DELETE',
      },
      'PAYMENT': {
        '*': 'CONTEXT/OPERATION/PAYMENT/*',
        'READ_SINGLE': 'CONTEXT/OPERATION/PAYMENT/READ-SINGLE',
        'CREDIT': 'CONTEXT/OPERATION/PAYMENT/CREDIT',
        'CONFIRM': 'CONTEXT/OPERATION/PAYMENT/CONFIRM',
        'CREATE': 'CONTEXT/OPERATION/PAYMENT/CREATE',
      },
      'TASK_LIST': {
        '*': 'CONTEXT/OPERATION/TASK-LIST/*',
        'READ_COLLECTION_VIA_TASK': 'CONTEXT/OPERATION/TASK-LIST/READ-COLLECTION-VIA-TASK',
      },
    },
    'FIELD': {
      '*': 'CONTEXT/FIELD/*',
    },
    'TENANT': {
      '*': 'CONTEXT/TENANT/*',
      'OPERATION': {
        '*': 'CONTEXT/TENANT/OPERATION/*',
        'TENANT': {
          '*': 'CONTEXT/TENANT/OPERATION/TENANT/*',
          'READ_SINGLE': 'CONTEXT/TENANT/OPERATION/TENANT/READ-SINGLE',
        },
        'TENANT_SETTING': {
          '*': 'CONTEXT/TENANT/OPERATION/TENANT-SETTING/*',
          'READ_VIA_TENANT': 'CONTEXT/TENANT/OPERATION/TENANT-SETTING/READ-VIA-TENANT',
          'CREATE': 'CONTEXT/TENANT/OPERATION/TENANT-SETTING/CREATE',
          'UPDATE': 'CONTEXT/TENANT/OPERATION/TENANT-SETTING/UPDATE',
          'DELETE': 'CONTEXT/TENANT/OPERATION/TENANT-SETTING/DELETE',
        },
      },
      'FIELD': {
        '*': 'CONTEXT/TENANT/FIELD/*',
      },
    },
    'ORGANIZATION': {
      '*': 'CONTEXT/ORGANIZATION/*',
      'OPERATION': {
        '*': 'CONTEXT/ORGANIZATION/OPERATION/*',
      },
      'FIELD': {
        '*': 'CONTEXT/ORGANIZATION/FIELD/*',
      },
    },
    'INDIVIDUAL': {
      '*': 'CONTEXT/INDIVIDUAL/*',
      'OPERATION': {
        '*': 'CONTEXT/INDIVIDUAL/OPERATION/*',
      },
      'FIELD': {
        '*': 'CONTEXT/INDIVIDUAL/FIELD/*',
      },
    },
    'THIRD_PARTY': {
      '*': 'CONTEXT/THIRD-PARTY/*',
      'OPERATION': {
        '*': 'CONTEXT/THIRD-PARTY/OPERATION/*',
      },
      'FIELD': {
        '*': 'CONTEXT/THIRD-PARTY/FIELD/*',
      },
    },
    'ADMINISTRATION': {
      '*': 'CONTEXT/ADMINISTRATION/*',
      'OPERATION': {
        '*': 'CONTEXT/ADMINISTRATION/OPERATION/*',
      },
      'FIELD': {
        '*': 'CONTEXT/ADMINISTRATION/FIELD/*',
      },
    },
    'CLIENT': {
      '*': 'CONTEXT/CLIENT/*',
      'OPERATION': {
        '*': 'CONTEXT/CLIENT/OPERATION/*',
        'ACTIVITY': {
          '*': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/READ-COLLECTION-VIA-CASE-FILE',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/ACTIVITY/DELETE',
        },
        'CASE_FILE': {
          '*': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/*',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE/DELETE',
        },
        'CASE_FILE_DEBTOR_EXTRA_COST': {
          '*': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/READ-COLLECTION-VIA-CASE-FILE',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-EXTRA-COST/DELETE',
        },
        'CASE_FILE_DEBTOR_INVOICE': {
          '*': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/READ-COLLECTION-VIA-CASE-FILE',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-DEBTOR-INVOICE/DELETE',
        },
        'CASE_FILE_SETTLEMENT': {
          '*': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-SETTLEMENT/*',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-SETTLEMENT/READ-SINGLE',
          'UPSERT': 'CONTEXT/CLIENT/OPERATION/CASE-FILE-SETTLEMENT/UPSERT',
        },
        'CLIENT': {
          '*': 'CONTEXT/CLIENT/OPERATION/CLIENT/*',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/CLIENT/READ-SINGLE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/CLIENT/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/CLIENT/DELETE',
        },
        'COLLECTION_COST_GROUP': {
          '*': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/*',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/COLLECTION-COST-GROUP/DELETE',
        },
        'INDIVIDUAL': {
          '*': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/*',
          'READ_COLLECTION_CHILD_VIA_ORGANIZATION': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/READ-COLLECTION-CHILD-VIA-ORGANIZATION',
          'READ_COLLECTION_OWNING_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/READ-COLLECTION-OWNING-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/DELETE',
          'CREATE_CONTACT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/CREATE-CONTACT',
          'UPDATE_CONTACT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/UPDATE-CONTACT',
          'DELETE_CONTACT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/DELETE-CONTACT',
          'CREATE_LOGIN_CONTACT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/CREATE-LOGIN-CONTACT',
          'DELETE_LOGIN_CONTACT': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL/DELETE-LOGIN-CONTACT',
        },
        'INDIVIDUAL_LOGIN': {
          '*': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL-LOGIN/*',
          'CREATE_LOGIN': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL-LOGIN/CREATE-LOGIN',
          'DELETE_LOGIN': 'CONTEXT/CLIENT/OPERATION/INDIVIDUAL-LOGIN/DELETE-LOGIN',
        },
        'INTEREST_RATE_GROUP': {
          '*': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/*',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/INTEREST-RATE-GROUP/DELETE',
        },
        'INVOICE': {
          '*': 'CONTEXT/CLIENT/OPERATION/INVOICE/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/INVOICE/READ-COLLECTION-VIA-CASE-FILE',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/INVOICE/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/INVOICE/READ-SINGLE',
        },
        'INVOICE_LINE': {
          '*': 'CONTEXT/CLIENT/OPERATION/INVOICE-LINE/*',
          'READ_COLLECTION_VIA_INVOICE': 'CONTEXT/CLIENT/OPERATION/INVOICE-LINE/READ-COLLECTION-VIA-INVOICE',
        },
        'NOTIFICATION_LINK': {
          '*': 'CONTEXT/CLIENT/OPERATION/NOTIFICATION-LINK/*',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/NOTIFICATION-LINK/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/NOTIFICATION-LINK/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/NOTIFICATION-LINK/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/NOTIFICATION-LINK/DELETE',
        },
        'ORGANIZATION': {
          '*': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/*',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/ORGANIZATION/DELETE',
        },
        'PAYMENT': {
          '*': 'CONTEXT/CLIENT/OPERATION/PAYMENT/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/READ-COLLECTION-VIA-CASE-FILE',
          'READ_COLLECTION_VIA_INVOICE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/READ-COLLECTION-VIA-INVOICE',
          'READ_SINGLE_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/READ-SINGLE-CASE-FILE',
          'CREDIT_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CREDIT-CASE-FILE',
          'CONFIRM_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CONFIRM-CASE-FILE',
          'CREATE_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CREATE-CASE-FILE',
          'READ_SINGLE_INVOICE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/READ-SINGLE-INVOICE',
          'CONFIRM_INVOICE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CONFIRM-INVOICE',
          'CREDIT_INVOICE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CREDIT-INVOICE',
          'CREATE_INVOICE': 'CONTEXT/CLIENT/OPERATION/PAYMENT/CREATE-INVOICE',
        },
        'PAYMENT_PLAN_GROUP': {
          '*': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/READ-COLLECTION-VIA-CASE-FILE',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/READ-SINGLE',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/CREATE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/PAYMENT-PLAN-GROUP/DELETE',
        },
        'PAYOUT': {
          '*': 'CONTEXT/CLIENT/OPERATION/PAYOUT/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/PAYOUT/READ-COLLECTION-VIA-CASE-FILE',
        },
        'STATISTIC': {
          '*': 'CONTEXT/CLIENT/OPERATION/STATISTIC/*',
          'READ_SINGLE_CLIENT': 'CONTEXT/CLIENT/OPERATION/STATISTIC/READ-SINGLE-CLIENT',
        },
        'SUBSCRIPTION': {
          '*': 'CONTEXT/CLIENT/OPERATION/SUBSCRIPTION/*',
          'READ_COLLECTION_VIA_CLIENT': 'CONTEXT/CLIENT/OPERATION/SUBSCRIPTION/READ-COLLECTION-VIA-CLIENT',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/SUBSCRIPTION/READ-SINGLE',
        },
        'SUBSCRIPTION_LINE': {
          '*': 'CONTEXT/CLIENT/OPERATION/SUBSCRIPTION-LINE/*',
          'READ_COLLECTION_VIA_SUBSCRIPTION': 'CONTEXT/CLIENT/OPERATION/SUBSCRIPTION-LINE/READ-COLLECTION-VIA-SUBSCRIPTION',
        },
        'THIRD_PARTY': {
          '*': 'CONTEXT/CLIENT/OPERATION/THIRD-PARTY/*',
          'READ_COLLECTION_VIA_CASE_FILE': 'CONTEXT/CLIENT/OPERATION/THIRD-PARTY/READ-COLLECTION-VIA-CASE-FILE',
        },
        'USER_PERMISSION': {
          '*': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/*',
          'READ_COLLECTION_VIA_INDIVIDUAL': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/READ-COLLECTION-VIA-INDIVIDUAL',
          'CREATE': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/CREATE',
          'READ_SINGLE': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/READ-SINGLE',
          'UPDATE': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/UPDATE',
          'DELETE': 'CONTEXT/CLIENT/OPERATION/USER-PERMISSION/DELETE',
        },
      },
      'FIELD': {
        '*': 'CONTEXT/CLIENT/FIELD/*',
        'PAYMENT': {
          AUTO_CONFIRM_CASE_FILE: 'CONTEXT/CLIENT/FIELD/PAYMENT/AUTO-CONFIRM-CASE-FILE',
          AUTO_CONFIRM_INVOICE: 'CONTEXT/CLIENT/FIELD/PAYMENT/AUTO-CONFIRM-INVOICE',
        },
      },
    },
    'CASE_FILE_LIST': {
      '*': 'CONTEXT/CASE-FILE-LIST/*',
      'OPERATION': {
        '*': 'CONTEXT/CASE-FILE-LIST/OPERATION/*',
      },
      'FIELD': {
        '*': 'CONTEXT/CASE-FILE-LIST/FIELD/*',
      },
    },
  },
} as const
export type PermissionsResources = keyof typeof PermissionResources
export type PermissionsContexts =
    keyof (typeof PermissionAttributes)['CONTEXT']
export type PermissionsGlobals = keyof (typeof PermissionAttributes)['GLOBAL']
// Extract top-level keys (e.g., GLOBAL, CONTEXT)

export type PermissionsPrefixes = keyof typeof PermissionAttributes

function testIt(v: keyof typeof PermissionAttributes) {
  console.log(v)
}

testIt('GLOBAL')

export type PermissionTypes = 'OPERATION' | 'FIELD'

export enum PermissionCategory {
  CASEFILE = 'CASE-FILE',
  CLIENT = 'CLIENT',
}

export interface PermissionObject {
  resource: Permission
  category: string
  name: string
  fullName: string
  notice: string
  active: boolean
  prefix: string
  warning: string
}

export interface PermissionHydraItem extends HydraContext {
  resource: string
  attribute: string
}

export interface UserPermissionHydraItem extends HydraContext {
  attributes: string[]
  user: string
  client?: string
  organization?: string
  individual?: string
  thirdParty?: string
  adminstration?: string
  userGroup?: string
}

export type UserPermissionHydraCollectionItem = UserPermissionHydraItem

export interface UserPermissionIndividualHydraItem extends HydraContext {
  'user': string
  '@id': string
  '@type': string
  'scope': PermissionScopeEnum
  'attributes': string[]
  'id': number
  'createdAt': string
  'updatedAt': string
  'client'?: string
  'organization'?: string
  'individual'?: string
  'thirdParty'?: string
  'adminstration'?: string
  'caseFileList'?: string
}
