import type { InferType } from 'yup'
import type { HydraContext, HydraTimeBased } from '../../../../common'
import { mixed, object, string } from 'yup'
import { CaseFileSortEnum } from '../case_file.interface.ts'

export enum CaseFileCategoryEnum {
  Open = 1,
  OnHold = 2,
  Closed = 3,
}

export function caseFileCategoryEnumOptions(t: ReturnType<typeof useI18n>['t']) {
  return Object.values(CaseFileCategoryEnum).filter(v => typeof v === 'number').map(value => ({
    label: t(`case_file.status.category.options.${value}`),
    value,
  }))
}

export function getCaseFileStatusCategoryStatusSeverity(status: {
  label: string
  value: CaseFileCategoryEnum
}) {
  switch (status.value) {
    case CaseFileCategoryEnum.Open:
      return 'success'
    case CaseFileCategoryEnum.OnHold:
      return 'info'
    case CaseFileCategoryEnum.Closed:
      return 'danger'
  }
}

export interface StatusesStats {
  statuses: number
}

export interface CaseFileStatusHydraItem extends HydraContext, HydraTimeBased {
  name: string
  category: CaseFileCategoryEnum
  description?: string
  sort: CaseFileSortEnum
}

export type CaseFileStatusHydraCollectionItem = CaseFileStatusHydraItem

export function statusObjectSchema() {
  return object({
    name: string().required().default(''),
    description: string().optional().default(''),
    category: mixed().oneOf(Object.values(CaseFileCategoryEnum)).required().default(CaseFileCategoryEnum.Open),
    sort: mixed().oneOf(Object.values(CaseFileSortEnum)).required().default(CaseFileSortEnum.CREDIT_MANAGEMENT),
  })
}

export type StatusObjectSchema = InferType<ReturnType<typeof statusObjectSchema>>

export type CaseFileStatusWrite = Pick<CaseFileStatusHydraItem, 'name' | 'sort' | 'description' | 'category'>
