<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Dropdown as VDropdown } from 'floating-vue'
import { AdvancedCurrencyFilterOperator } from '../../../../common'

export interface Props {
  modelValue: number | null
  disabled?: boolean
}
defineProps<Props>()
const emit = defineEmits(['filterCallback'])
const modelValue = defineModel<string | null>('modelValue', {
  default: null,
})

const matchOperator = defineModel<AdvancedCurrencyFilterOperator>('matchOperator', {
  default: AdvancedCurrencyFilterOperator.EQUALS,
})
const innerValue = ref<number | undefined>((() => {
  let newNumber: number | undefined
  if (modelValue.value && typeof modelValue.value === 'string') {
    if (matchOperator.value === AdvancedCurrencyFilterOperator.BETWEEN) {
      newNumber = Number(modelValue.value.split('..')[0])
    }
    else {
      newNumber = Number(modelValue.value)
    }
  }
  if (Number.isNaN(newNumber)) {
    newNumber = undefined
  }
  return newNumber
})())

const secondInnerValue = ref<number | undefined>((() => {
  let newNumber: number | undefined
  if (modelValue.value) {
    if (matchOperator.value === AdvancedCurrencyFilterOperator.BETWEEN) {
      newNumber = Number(modelValue.value.split('..')[1])
    }
  }
  if (Number.isNaN(newNumber)) {
    newNumber = undefined
  }
  return newNumber
})())

const { t } = useI18n()

function triggerEnter() {
  if (matchOperator.value === AdvancedCurrencyFilterOperator.BETWEEN) {
    if (innerValue.value !== null && secondInnerValue.value !== null) {
      emit('filterCallback')
    }
  }
  else {
    emit('filterCallback')
  }
}

watch([() => innerValue.value, () => secondInnerValue.value], () => {
  if (matchOperator.value === AdvancedCurrencyFilterOperator.BETWEEN) {
    modelValue.value = `${innerValue.value}..${secondInnerValue.value}`
  }
  else {
    if (secondInnerValue.value) {
      secondInnerValue.value = 0
    }
    modelValue.value = `${innerValue.value}`
  }
})
</script>

<template>
  <div class="flex flex-row gap-2 align-items-center px-1">
    <InputNumber
      v-model="innerValue"
      mode="currency"
      :disabled="disabled"
      currency="EUR"
      locale="nl-NL"
      :placeholder="t('common.search_on_amount')"
      @keydown.enter="triggerEnter"
    />

    <InputNumber
      v-if="matchOperator === 'between'"
      v-model="secondInnerValue"
      mode="currency"
      :disabled="disabled"
      currency="EUR"
      locale="nl-NL"
      :placeholder="t('common.search_on_amount')"
      @keydown.enter="triggerEnter"
    />

    <div
      v-if="modelValue"
      class="cursor-pointer hover:text-200"
      @click="
        () => {
          modelValue = null
          $emit('filterCallback');
        }
      "
    >
      <FontAwesomeIcon :icon="['far', 'times']" class="text-lg" />
    </div>
    <VDropdown
      show-group="filters"
      :distance="6"
    >
      <div
        class="cursor-pointer hover:text-200"
      >
        <FontAwesomeIcon :icon="['far', 'filter']" class="text-lg" :class="{ 'text-primary-500': matchOperator !== 'equals' }" />
      </div>
      <template #popper>
        <div class="flex flex-column gap-0 p-2">
          <div
            v-for="operator in Object.values(AdvancedCurrencyFilterOperator)"
            :key="operator"
          >
            <div
              class="cursor-pointer hover:text-primary-800 bg-surface hover:bg-primary-50 p-2 rounded-md"
              :class="{ 'text-primary-500 bg-primary-50': operator === matchOperator }"
              @click="() => {

                matchOperator = operator
                if (modelValue?.includes('..') && operator !== AdvancedCurrencyFilterOperator.BETWEEN) {
                  modelValue = `${innerValue}`
                }
                if (operator === AdvancedCurrencyFilterOperator.BETWEEN && secondInnerValue === undefined) {
                  secondInnerValue = innerValue
                }
                $emit('filterCallback')
              }"
            >
              {{ $t(`common.filters.advanced_currency.operators.${operator}`) }}
            </div>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<style scoped lang="scss"></style>
