import type { Ref } from 'vue'
import type { ColumnsType, ComputedColumnsType } from './table.interface.ts'
import { createInjectionState } from '@vueuse/shared'

export const ColumnStateIdentifier = 'table-columns'
type ColumnStateInterface = Record<
  string,
  {
    visible: boolean
    position: number
  }
>

// Object.keys(localStorage).forEach(key => {
//     if (key.includes("table-columns")) {
//         localStorage.removeItem(key);
//     }
// });
const [useProvideColumns, useColumnsRaw] = createInjectionState(
  (
    initialColumns: ColumnsType<any>,
    identifier: string,
    hasMulti: boolean | Ref<boolean> = false,
    hasExpand = false,
  ) => {
    const columnsState = useStorage<ColumnStateInterface>(
      `${ColumnStateIdentifier.toString()}_${identifier}`,
      initialColumns.reduce(
        (a, b) => ({
          ...a,
          [b.field]: {
            visible: b.visible === undefined ? true : b.visible,
            position: 0,
          },
        }),
        {} as ColumnStateInterface,
      ),
    )

    const resetColumns = () => {
      columnsState.value = initialColumns.reduce(
        (a, b) => ({
          ...a,
          [b.field]: {
            visible: b.visible === undefined ? true : b.visible,
            position: 0,
          },
        }),
        {} as ColumnStateInterface,
      )
    }
    const columns = computed<ComputedColumnsType<any>>({
      get: () => {
        return [
          ...(hasExpand
            ? [
                {
                  type: ColumnsTypeEnum.Expand,
                  field: 'expander',
                  header: '',
                  visible: true,
                  position: 0,
                },
              ]
            : []),
          ...((isRef(hasMulti)
            ? hasMulti.value
            : hasMulti)
            ? [
                {
                  type: ColumnsTypeEnum.Multi,
                  field: 'multiselect',
                  header: '',
                  visible: true,
                  position: 0,
                },
              ]
            : []),
          ...initialColumns
            .map((c) => {
              const state = columnsState.value[c.field as string]
              return {
                ...c,
                visible: state ? state.visible : true,
                type: c.type === undefined ? ColumnsTypeEnum.Default : c.type,
                position: state ? state.position : 0,
              }
            })
            .sort((a, b) =>
              a?.position && b?.position ? a.position - b.position : 0,
            ),
        ]
      },
      set: cols =>
        cols.forEach((c, index) => {
          const state = columnsState.value[c.field as string]
          if (!state)
            return
          state.position = index + 1
        }),
    })

    return { columns, columnsState, resetColumns }
  },
)

function useColumns() {
  const columnsStore = useColumnsRaw()
  if (columnsStore == null) {
    throw new Error(
      'Please call `useProvideColumns` on the appropriate parent component',
    )
  }
  return columnsStore
}

export { useColumns, useProvideColumns }
