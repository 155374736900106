import type { UserHydraCollectionItem } from './user.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { useAuthStore } from '../../auth'

export const useUserStore = createGlobalState(() => {
  const items = ref<UserHydraCollectionItem[]>([])
  const loading = ref(false)
  const initialLoading = ref(false)
  const employees = useStorage<Record<string, UserHydraCollectionItem> | null>(
    'employees',
    null,
    undefined,
    { serializer: StorageSerializers.object },
  )

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  const { hasPermission } = usePermissions()

  const allowReadEmployees = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.USER.READ_COLLECTION_EMPLOYEES,
    ),
  )
  const { user } = useAuthStore()

  async function setEmployees(): Promise<void> {
    if (!allowReadEmployees.value) {
      employees.value = null
      return
    }
    await getAll<UserHydraCollectionItem>({ url: '/api/users/employees' }).then(
      (data: UserHydraCollectionItem[]) => {
        employees.value = {
          ...(user.value ? { [user.value['@id']]: user.value } : {}),
          ...data.reduce(
            (acc, item) => {
              acc[item['@id']] = item
              return acc
            },
            {} as Record<string, UserHydraCollectionItem>,
          ),
        }
        return data
      },
    )
  }

  const readEmployeesOptions = computed(() => {
    return employees.value
      ? Object.values(employees.value).map(user => ({
        label: user.name,
        value: user['@id'],
      }))
      : []
  })
  const readEmployeesOptionsIdBased = computed(() => {
    return employees.value
      ? Object.values(employees.value).map(user => ({
        label: user.name,
        value: user.id,
      }))
      : []
  })

  return {
    setEmployees,
    readEmployeesOptions,
    employees,
    readEmployeesOptionsIdBased,
    allowReadEmployees,
    reset,
    items,
    loading,
    initialLoading,
  }
})
