import type { I18nOptions } from 'vue-i18n'
import app from '@core/configApp'
import { AcceptLanguageRequestInterceptor, en, installInterceptor, nl } from 'lib'
import { createI18n } from 'vue-i18n'

const options: I18nOptions = {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    nl,
    en,
  },
  numberFormats: {
    nl: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
  },
}
export const i18n = createI18n<false, typeof options>(options)
installInterceptor(AcceptLanguageRequestInterceptor(() => {
  return i18n.global.locale.value
}))
// export type MessageSchema = typeof nlNL
//
// const i18n = createI18n<[MessageSchema], 'nl-NL'>({
//   legacy: false,
//   locale: 'nl-NL',
//   messages: {
//     'nl-NL': nlNL,
//   },
//   numberFormats: {
//     'nl-NL': {
//       currency: {
//         style: 'currency',
//         currency: 'EUR',
//       },
//     },
//   },
// })

app.use(i18n)
