import type { MediaObjectHydraItem } from '../media_objects'
import type { EntityReferenceValueObjectSchema, ValueObjectSchemas } from './documents.schema.ts'
import type { EmbeddedPartDocumentSchema } from './embeddedPartDocuments.schema.ts'
// useSourceViewer.ts
import { createInjectionState } from '@vueuse/shared'
import { ref } from 'vue'
import { AbstractValueTypeEnum } from './documents.interface.ts'

const [useProvideDocumentMediaObjects, useDocumentMediaObjectsRaw]
  = createInjectionState(() => {
    // state
    const mediaObjects = ref<Record<string, MediaObjectHydraItem>>({})

    const documentTypeFormatters = computed(() => {
      return {
        [AbstractValueTypeEnum.EntityReference]: async (
          document: EntityReferenceValueObjectSchema,
        ) => {
          return mediaObjects.value[document.iri]?.originalName || ''
        },
      }
    })

    const setInitialOriginalMediaObjectsBasedOnValueObjectSchemas = async (values: ValueObjectSchemas[]) => {
      for (const value of values) {
        if (value['#type'] === AbstractValueTypeEnum.EntityReference) {
          const schema = value as EntityReferenceValueObjectSchema
          const { data } = await api.get<MediaObjectHydraItem>(schema.iri)
          mediaObjects.value[schema.iri] = data
        }
      }
    }

    const setInitialOriginalMediaObjectsBasedOnEmbeddedPartDocumentSchema = async (values: EmbeddedPartDocumentSchema[]) => {
      for (const value of values) {
        if (value.mediaObject['#type'] === AbstractValueTypeEnum.EntityReference) {
          const schema = value.mediaObject as EntityReferenceValueObjectSchema
          const { data } = await api.get<MediaObjectHydraItem>(schema.iri)
          mediaObjects.value[schema.iri] = data
        }
      }
    }

    return {
      mediaObjects,
      setInitialOriginalMediaObjectsBasedOnValueObjectSchemas,
      setInitialOriginalMediaObjectsBasedOnEmbeddedPartDocumentSchema,
      documentTypeFormatters,
    }
  })
export { useProvideDocumentMediaObjects }

// If you want to hide `useSourceViewer` and wrap it in default value logic or throw error logic, please don't export `useSourceViewer`
export function useDocumentMediaObjects() {
  const counterStore = useDocumentMediaObjectsRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideDocumentMediaObjects` on the appropriate parent component',
    )
  }
  return counterStore
}
