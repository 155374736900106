import type { HydraCollection } from '../../../../../../common'
import type { ExactVatcodeHydraItem } from '../../../../../../composables'
import { useExactInternalSettings } from './useExactInternalSettings'

const [useProvideVatcodeMapStateOptions, useVatcodeMapStateRaw]
  = createInjectionState(
    ({ divisionId }: {
      divisionId: Ref<number>
    }) => {
      const { firstTenant } = useTenantsStore()
      const loadingVatcodes = ref(false)
      const vatcodes = ref<ExactVatcodeHydraItem[]>([])
      const { onError } = useServerErrorHandler()
      const { thirdPartyRelations } = useExactInternalSettings()
      const { getAdministration } = useAdministrationStore()
      const administration = computed(() => {
        const relation = thirdPartyRelations.value.find(relation => `${relation.thirdPartyReference}` === `${divisionId.value}`)
        if (!relation)
          return null
        return getAdministration(relation.administration) ?? null
      })
      const loadVatcodes = async (load = false): Promise<void> => {
        if (loadingVatcodes.value || !firstTenant.value || !administration.value)
          return
        if (load) {
          loadingVatcodes.value = true
        }
        api.get<HydraCollection<ExactVatcodeHydraItem>>(`/api/internal_apps/${firstTenant.value.id}/exact/${administration.value.id}/vat_codes`)
          .then(({ data }) => {
            vatcodes.value = data['hydra:member']
          })
          .catch((e) => {
            onError(e)
          })
          .finally(() => {
            loadingVatcodes.value = false
          })
      }

      watch(() => administration.value?.id, () => {
        loadVatcodes()
      }, {
        immediate: true,
      })

      const glAccountsOptions = computed(() =>
        vatcodes.value.map(account => ({
          label: `${account.code} - ${account.description} (${account.type})`,
          value: account.id,
        })),
      )

      return {
        glAccountsOptions,
        loadingVatcodes,
        vatcodes,
        administration,
      }
    },
  )
export { useProvideVatcodeMapStateOptions }

// If you want to hide `usePermissionsTableHelper` and wrap it in default value logic or throw error logic, please don't export `usePermissionsTableHelper`
export function useVatcodeMapState() {
  const permissionsStateRaw = useVatcodeMapStateRaw()
  if (permissionsStateRaw == null) {
    throw new Error(
      'Please call `useProvideVatcodeMapStateOptions` on the appropriate parent component',
    )
  }
  return permissionsStateRaw
}
