import type { InferType } from 'yup'
import { array, mixed, object } from 'yup'
import { AbstractValueTypeEnum } from '../../../../../../../composables'
import { automationValueObjectSchema } from '../../Condition/Value/condition_value.interface.ts'
import { AutomationActionTypesEnum } from '../automation_action_edit.interface.ts'

export const allowedCreateTaskTypesMap: Record<
  string,
  AbstractValueTypeEnum[]
> = {
  assignedUsers: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  taskLists: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  description: [
    AbstractValueTypeEnum.StringTemplate,
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  status: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  caseFile: [
    AbstractValueTypeEnum.EntityReference,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  deadlineAt: [
    AbstractValueTypeEnum.FixedValue,
    AbstractValueTypeEnum.ReferenceValue,
    AbstractValueTypeEnum.ExpressionValue,
  ],
  autoLinkCaseFileExecutor: [
    AbstractValueTypeEnum.FixedValue,
  ],
}

export function automationCreateTaskActionObjectSchema(
  t: (v: string) => string,
) {
  return object({
    '#type': mixed()
      .oneOf([AutomationActionTypesEnum.CreateTaskAction])
      .required(t('common.forms.required'))
      .default(AutomationActionTypesEnum.CreateTaskAction),
    'description': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateTaskTypesMap.description,
    }),
    'status': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateTaskTypesMap.status,
    }),
    'assignedUsers': array()
      .of(
        automationValueObjectSchema(t, {
          allowedTypes: allowedCreateTaskTypesMap.assignedUsers,
        }),
      )
      .default([]),
    'taskLists': array()
      .of(
        automationValueObjectSchema(t, {
          allowedTypes: allowedCreateTaskTypesMap.taskLists,
        }),
      )
      .default([]),
    'autoLinkCaseFileExecutor': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateTaskTypesMap.autoLinkCaseFileExecutor,
    }),
    'caseFile': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateTaskTypesMap.caseFile,
      required: false,
    }),
    'deadlineAt': automationValueObjectSchema(t, {
      allowedTypes: allowedCreateTaskTypesMap.deadlineAt,
      required: false,
    }),
  })
}

export type AutomationCreateTaskActionObjectSchema = InferType<
  ReturnType<typeof automationCreateTaskActionObjectSchema>
>
